<template>
    <div>
        <el-form size="small" label-position="left" label-width="100px" class="lywidgetsetting" @submit.prevent>
            <el-collapse v-model="lyCollapseActiveList" class="lyformsetting-collapse">
                <el-collapse-item name="lyws01" title="组件属性">
                    <el-form-item label="字段标识">
                        <el-input  v-model="optionModel.name" @change="handleChange"></el-input>
                    </el-form-item>
                    <el-form-item label="隐藏">
                        <el-switch v-model="optionModel.hidden" @change="handleChange"></el-switch>
                    </el-form-item>
                    <el-form-item label="静态文字">
                        <el-input v-model="optionModel.textContent" type="textarea" :rows="2"  style="width: 100%" @change="handleChange"></el-input>
                    </el-form-item>
                    <el-form-item label="显示省略号">
                        <el-switch v-model="optionModel.truncated" @change="handleChange"></el-switch>
                    </el-form-item>
                    <el-form-item label="尺寸">
                        <el-select v-model="optionModel.size" @change="handleChange">
                            <el-option v-for="item in formSizes" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="字体大小">
                        <el-input v-model="optionModel.fontSize" @change="handleChange"></el-input>
                    </el-form-item>
                    <el-form-item label="文字类型">
                        <el-select v-model="optionModel.type" :allow-create="true" filterable @change="handleChange">
                            <el-option label="default" value=""></el-option>
                            <el-option label="primary" value="primary"></el-option>
                            <el-option label="success" value="success"></el-option>
                            <el-option label="info" value="info"></el-option>
                            <el-option label="warning" value="warning"></el-option>
                            <el-option label="danger" value="danger"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="文字对齐">
                        <el-radio-group v-model="optionModel.labelPosition" class="radio-group-custom" @change="handleChange">
                            <el-radio-button label="left">左</el-radio-button>
                            <el-radio-button label="center">中</el-radio-button>
                            <el-radio-button label="right">右</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="标签类型">
                        <el-select v-model="optionModel.tag" :allow-create="true" filterable @change="handleChange">
                            <el-option label="div" value="div"></el-option>
                            <el-option label="span" value="span"></el-option>
                            <el-option label="i" value="i"></el-option>
                            <el-option label="p" value="p"></el-option>
                            <el-option label="b" value="b"></el-option>
                            <el-option label="ins" value="ins"></el-option>
                            <el-option label="del" value="del"></el-option>
                            <el-option label="mark" value="mark"></el-option>
                        </el-select>
                    </el-form-item>
                    <customClassLy :selected-widget="selectedWidget" :option-model="optionModel"></customClassLy>
                </el-collapse-item>
            </el-collapse>
        </el-form>
    </div>
</template>

<script setup>
    import {ref, onMounted, reactive, computed, watch} from 'vue'
    import { useBuilderStore } from "@/store/lyFormBuilder";
    import validationCommonLy from "@/components/lyform-builder/lysetting-pannel/lywidget-common/validation-common-ly"
    import customClassLy from "@/components/lyform-builder/lysetting-pannel/lywidget-common/custom-class-ly"
    const props = defineProps({
        selectedWidget: Object,
        optionModel: Object,
    })
    const builderStore = useBuilderStore()
    function handleChange(newValue) {
         builderStore.saveCurrentHistoryStep()
    }
    let lyCollapseActiveList =  ref(['lyws01','lyws02','lyws03'])
    const formSizes = ref([
        {label: 'default', value: 'default'},
        {label: 'large', value: 'large'},
        {label: 'small', value: 'small'},
    ])
    function showEventCollapse(){
        if (builderStore.builderConfig['showEventCollapse'] === undefined) {
          return true
        }
        return !!builderStore.builderConfig['showEventCollapse']
    }
    function editFormEventHandler(flag){

    }

</script>

<style lang="scss" scoped>
    .lywidgetsetting{
        ::v-deep(.el-form-item__label) {
            font-size: 13px;
            overflow: hidden;
            white-space: nowrap;
        }
        .lyformsetting-collapse{
            ::v-deep(.el-collapse-item__header) {
                font-weight: bold;
            }
        }
    }

</style>