<template>
    <div>
        <containers-wrapper-ly v-if="designState" :widget="widget" :parent-widget="parentWidget" :parent-list="parentList" :index-of-parent-list="indexOfParentList" :design-state="designState">
            <el-card :key="widget.id" @click.stop="selectWidget(widget,designState)" :shadow="widget.options.shadow" :style="{width: widget.options.cardWidth + '!important' || ''}" :class="[designState ? 'lycard-container':'',selected ? 'selected' : '', !!widget.options.folded ? 'folded' : '',!!widget.options.classicsMode? 'classicsMode' : '', customClass]">
                <template #header v-if="widget.options.showHeader">
                    <div class="lycard-header">
                        <span class="lycard-header-title">{{widget.options.label}}</span>
                        <span v-if="widget.options.showFold" class="lycard-arrow" @click="toggleCard">
                            <template v-if="!widget.options.folded">
                                <el-icon><ArrowDown /></el-icon>
                            </template>
                            <template v-else>
                                <el-icon><ArrowUp /></el-icon>
                            </template>
                        </span>
                    </div>
                </template>
                <draggable :list="widget.widgetList" item-key="id" v-bind="{group:'lyDragGroup', ghostClass: 'lyformbuilder-ghost',animation: 200}"
                           handle=".drag-handler" tag="transition-group" :component-data="{name: 'fade'}"
                           @add="(evt) => onCardDragAdd(evt, widget.widgetList)"
                           @update="onCardDragUpdate" :move="handleContainerMove">
                    <template #item="{ element: subWidget, index: swIdx }">
                        <div class="form-widget-list">
                            <template v-if="subWidget.category === 'container'">
                                <component :is="subWidget.type + '-widget-ly'" :widget="subWidget" :key="subWidget.id" :parent-list="widget.widgetList" :index-of-parent-list="swIdx" :parent-widget="widget" :design-state="true"></component>
                            </template>
                            <template v-else>
                                <component :is="subWidget.type + '-widget-ly'" :field="subWidget" :key="subWidget.id" :parent-list="widget.widgetList" :index-of-parent-list="swIdx" :parent-widget="widget" :design-state="true"></component>
                            </template>
                        </div>
                    </template>
                </draggable>
            </el-card>
        </containers-wrapper-ly>
        <containers-wrapper-ly v-else :widget="widget">
            <el-card :key="widget.id" class="lycard-container" :class="[!!widget.options.folded ? 'folded' : '',!!widget.options.classicsMode? 'classicsMode' : '', customClass]" :shadow="widget.options.shadow" :style="{width: widget.options.cardWidth + '!important' || ''}" :ref="widget.id" v-show="!widget.options.hidden">
                <template #header v-if="widget.options.showHeader">
                    <div class="lycard-header">
                        <span class="lycard-header-title">{{widget.options.label}}</span>
                        <span v-if="widget.options.showFold" class="lycard-arrow" @click="toggleCard">
                            <template v-if="!widget.options.folded">
                                <el-icon><ArrowDown /></el-icon>
                            </template>
                            <template v-else>
                                <el-icon><ArrowUp /></el-icon>
                            </template>
                        </span>
                    </div>
                </template>
                <template v-if="!!widget.widgetList && (widget.widgetList.length > 0)">
                    <template v-for="(subWidget, swIdx) in widget.widgetList">
                        <template v-if="subWidget.category === 'container'">
                            <component :is="subWidget.type + '-widget-ly'" :widget="subWidget" :key="swIdx" :parent-list="widget.widgetList" :index-of-parent-list="swIdx" :parent-widget="widget" :design-state="false"></component>
                        </template>
                        <template v-else>
                            <component :is="subWidget.type + '-widget-ly'" :field="subWidget" :key="swIdx" :parent-list="widget.widgetList" :index-of-parent-list="swIdx" :parent-widget="widget" :design-state="false"></component>
                        </template>
                    </template>
                </template>
            </el-card>
        </containers-wrapper-ly>
    </div>

</template>

<script setup>
    import {ref, onMounted, reactive, computed, nextTick, watch} from 'vue'
    import useCommon from '@/components/lyform-builder/lyform-design/useCommon'
    import { useBuilderStore } from "@/store/lyFormBuilder";

    const props = defineProps({
        widget: Object,
        parentWidget: Object,
        parentList: Array,
        indexOfParentList: Number,
        designState: {
            type: Boolean,
            default: false
        },
    })
    const builderStore = useBuilderStore()
    const selected = computed(() => {
        return props.designState&&props.widget.id === builderStore.selectedId
    })
    let {selectWidget} = useCommon()
    const customClass = computed(() => {
        return props.widget.options.customClass || ''
    })
    
    function toggleCard() {
        props.widget.options.folded = !props.widget.options.folded
    }
    function setFolded(folded) {
        props.widget.options.folded = !!folded
    }

    function onCardDragAdd(e, subList) {
        const newIndex = e.newIndex
        if (!!subList[newIndex]) {
            builderStore.setSelected( subList[newIndex] )
        }
        builderStore.emitHistoryChange()
    }

    function onCardDragUpdate() {
        builderStore.emitHistoryChange()
    }

    function handleContainerMove(e) {
        return builderStore.handleWidgetMove(e)
    }


</script>

<style lang="scss" scoped>
    .lycard-container.selected{
        outline: 2px solid var(--el-color-primary);
    }
    ::v-deep(.el-card__header) {
        padding: 15px 20px;
    }
    .lycard-header-title{
        font-size: 16px;
        /*font-weight: 700;*/
    }
    .classicsMode ::v-deep(.el-card__header){
        padding: 15px 20px 0 20px;
        border-bottom:none;
        .lycard-header-title{
            font-size: 17px;
            font-weight: 700;
        }
    }
    .folded ::v-deep(.el-card__body) {
        display: none;
    }
    .lycard-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .lycard-arrow {
        cursor: pointer;
    }
</style>