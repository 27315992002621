<!--
/**
 * description: 视频组件
 * author: lybbn
 * program django-vue-lyadmin
 * email: 1042594286@qq.com
 * website: http://www.lybbn.cn
 * date: 2023.05.26
 * @version: 1.0
 * 官方文档：https://help.aliyun.com/document_detail/125572.html
 */
-->
<template>
    <div :id="playerId" :class="[buttonCenter === true?'aliplayercenter':'']"></div>
</template>

<script setup>
    import { ref,computed,onMounted,nextTick,watch,onBeforeUnmount } from 'vue'
    import {deepClone} from "@/utils/util"

    let events = [
        "ready",
        "play",
        "pause",
        "canplay",
        "playing",
        "ended",
        "liveStreamStop",
        "onM3u8Retry",
        "hideBar",
        "showBar",
        "waiting",
        "timeupdate",
        "snapshoted",
        "requestFullScreen",
        "cancelFullScreen",
        "error",
        "startSeek",
        "completeSeek",
    ]

    const emit = defineEmits(["ready",
        "play",
        "pause",
        "canplay",
        "playing",
        "ended",
        "liveStreamStop",
        "onM3u8Retry",
        "hideBar",
        "showBar",
        "waiting",
        "timeupdate",
        "snapshoted",
        "requestFullScreen",
        "cancelFullScreen",
        "error",
        "startSeek",
        "completeSeek",])

    const props = defineProps({
        options:{
            type: [Object],
            default:() => null,
        },
        source: {
            type: [String],
            default: null,
        },
        cssLink: {
            //css版本源
            type: [String],
            default: `https://g.alicdn.com/de/prismplayer/2.15.5/skins/default/aliplayer-min.css`,
        },
        scriptSrc: {
            //js版本源
            type: [String],
            default: `https://g.alicdn.com/de/prismplayer/2.15.5/aliplayer-min.js`,
        },
        buttonCenter:{
            type:Boolean,
            default:false
        }

    })

    let player = ref(null) //播放器实例
    let playerId = ref (`player-${Math.random()
        .toString(36)
        .substr(2)
        .toLocaleUpperCase()}`)
    let lyconfig = ref({
        id: null, //播放器的ID
        width: "100%",
        autoplay: false,
    })

    function initPlayer(){
        if(window.Aliplayer != undefined){
            const options = deepClone(props.options);
            if (options) {
                for (const key in options) {
                    lyconfig.value[key] = options[key];
                }
            }
            if (props.source) lyconfig.value.source = props.source;
            lyconfig.value.id = playerId.value; //赋值播放器容器id
            player.value && player.value.dispose(); //防止实例的重复
            player.value = window.Aliplayer(lyconfig.value);
            for (const ev in events) {
                player.value &&
                player.value.on(events[ev], (e) => {
                    emit(events[ev], e);
                });
            }
        }
    }

    function init() {
        const linkID = "app__aliplayer-min-css";
        const scriptID = "app__aliplayer-min-js";
        const head = document.getElementsByTagName("head");
        const html = document.getElementsByTagName("html");
        let scriptTag = document.getElementById(scriptID);
        let linkIDTag = document.getElementById(linkID);
        if (!linkIDTag) {
            const link = document.createElement("link");
            link.type = "text/css";
            link.rel = "stylesheet";
            link.href = props.cssLink;
            link.id = linkID;
            head[0].appendChild(link);
        }
        if (!scriptTag) {
            scriptTag = document.createElement("script");
            scriptTag.type = "text/javascript";
            scriptTag.id = scriptID;
            scriptTag.src = props.scriptSrc;
            html[0].appendChild(scriptTag);
        } else {
            initPlayer(); //这样是为了兼容页面上有多个播放器
        }
        //兼容单页加载和硬加载
        scriptTag.addEventListener("load", () => {
            initPlayer();
        });
    }

    //获取播放器实例
    function getPlayer() {
        return player.value
    }

    //播放视频
    function play() {
        player.value && player.value.play();
    }

    //暂停视频
    function pause() {
        player.value && player.value.pause();
    }

    //重播视频
    function replay() {
        player.value && player.value.replay();
    }

    //设置封面
    function setCover(cover) {
        player.value && player.value.setCover(cover);
    }

    //播放器销毁
    function dispose() {
        player.value && player.value.dispose();
    }

    watch(()=>props.source,(n,o)=>{
        init()
    })

    watch(()=>props.options,(n,o)=>{
        init()
    })

    onMounted(()=>{
        nextTick(()=>{
            init()
        })
    })

    onBeforeUnmount(()=>{
        //销毁播放器
        dispose()
    })

</script>

<style scoped>
    .aliplayercenter:deep(.prism-big-play-btn){
        left: 50% !important;
        bottom: 50% !important;
        transform: translate(-50%, 50%);
    }

</style>