<template>
    <div>
        <containers-wrapper-ly v-if="designState" :widget="widget" :parent-widget="parentWidget" :parent-list="parentList" :index-of-parent-list="indexOfParentList" :design-state="designState">
            <div :key="widget.id" class="lytable-container" :class="[selected ? 'selected' : '', customClass]" @click.stop="selectWidget(widget,designState)">
                <table class="table-layout">
                    <tbody>
                        <tr v-for="(row, rowIdx) in widget.rows" :key="row.id">
                            <template v-for="(colWidget, colIdx) in row.cols">
                                <table-cell-widget-ly v-if="!colWidget.merged" :widget="colWidget"
                                :key="colWidget.id" :parent-list="widget.cols"
                                :row-index="rowIdx" :row-length="widget.rows.length"
                                :col-index="colIdx" :col-length="row.cols.length"
                                :col-array="row.cols" :row-array="widget.rows"
                                :parent-widget="widget" :design-state="designState"></table-cell-widget-ly>
                            </template>
                        </tr>
                    </tbody>
                </table>
            </div>
        </containers-wrapper-ly>
        <containers-wrapper-ly v-else :widget="widget">
            <div :key="widget.id" class="lytable-container2" v-show="!widget.options.hidden">
                <table :ref="widget.id" class="table-layout" :class="[customClass]">
                    <tbody>
                        <tr v-for="(row, rowIdx) in widget.rows" :key="row.id">
                            <template v-for="(colWidget, colIdx) in row.cols">
                                <table-cell-widget-ly v-if="!colWidget.merged" :widget="colWidget" :key="colIdx" :parent-list="widget.cols" :row-index="rowIdx" :col-index="colIdx" :parent-widget="widget" :design-state="designState"></table-cell-widget-ly>
                            </template>
                        </tr>
                    </tbody>
                </table>
            </div>
        </containers-wrapper-ly>
    </div>

</template>

<script setup>
    import {ref, onMounted, reactive, computed, nextTick, watch} from 'vue'
    import useCommon from '@/components/lyform-builder/lyform-design/useCommon'
    import { useBuilderStore } from "@/store/lyFormBuilder";

    const props = defineProps({
        widget: Object,
        parentWidget: Object,
        parentList: Array,
        indexOfParentList: Number,
        designState: {
            type: Boolean,
            default: false
        },
    })
    const builderStore = useBuilderStore()
    const selected = computed(() => {
        return props.designState&&props.widget.id === builderStore.selectedId
    })
    let {selectWidget} = useCommon()
    const customClass = computed(() => {
        return props.widget.options.customClass || ''
    })



</script>

<style lang="scss" scoped>
    .lytable-container {
        padding: 5px;
        border: 1px dashed #336699;
        box-sizing: border-box;

        table.table-layout {
            width: 100%;
            text-align: center;
            border-collapse: collapse;
            table-layout: fixed;
            text-align: center;

            ::v-deep(td) {
                height: 48px;
                border: 1px dashed #336699;
                padding: 3px;
                display: table-cell;
                vertical-align: middle!important;
                text-align: center;
            }

            .form-widget-list {
                border: 1px dashed #336699;
                min-height: 36px;
            }
        }
    }

    .lytable-container.selected {
        outline: 2px solid var(--el-color-primary) !important;
    }
    .lytable-container2{
        table.table-layout {
            width: 100%;
            table-layout: fixed;
            border-collapse: collapse;
            text-align: center;
        }
        ::v-deep(td) {
            vertical-align: middle!important;
            text-align: center;
        }
    }
</style>