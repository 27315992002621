<template>
    <div>
        <containers-wrapper-ly v-if="designState" :widget="widget" :parent-widget="parentWidget" :parent-list="parentList" :index-of-parent-list="indexOfParentList" :design-state="designState">
            <div :key="widget.id" class="lytab-container" :class="{'selected': selected}" @click.stop="selectWidget(widget,designState)">
                <el-tabs :type="widget.displayType" v-model="activeTab" @tab-click="onTabClick">
                    <el-tab-pane v-for="(tab, index) in widget.tabs" :key="index" :label="tab.options.label" :name="tab.options.name" @click.stop="selectWidget(widget,designState)">
                        <draggable :list="tab.widgetList" item-key="id" v-bind="{group:'lyDragGroup', ghostClass: 'lyformbuilder-ghost',animation: 200}"
                            handle=".drag-handler" tag="transition-group" :component-data="{name: 'fade'}"
                            @add="(evt) => onContainerDragAdd(evt, tab.widgetList)"
                            @update="onContainerDragUpdate" :move="handleContainerMove">
                            <template #item="{ element: subWidget, index: swIdx }">
                                <div class="form-widget-list">
                                    <template v-if="subWidget.category === 'container'">
                                        <component :is="subWidget.type + '-widget-ly'" :widget="subWidget" :key="subWidget.id" :parent-list="tab.widgetList" :index-of-parent-list="swIdx" :parent-widget="widget" :design-state="designState"></component>
                                    </template>
                                    <template v-else>
                                        <component :is="subWidget.type + '-widget-ly'" :field="subWidget" :key="subWidget.id" :parent-list="tab.widgetList" :index-of-parent-list="swIdx" :parent-widget="widget" :design-state="designState"></component>
                                    </template>
                                </div>
                            </template>
                        </draggable>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </containers-wrapper-ly>
        <containers-wrapper-ly v-else :widget="widget">
            <div :key="widget.id" v-show="!widget.options.hidden">
                <el-tabs v-model="activeTabName" :type="widget.displayType" :ref="widget.id" :class="[customClass]" @tab-click="onTabClick">
                    <el-tab-pane v-for="(tab, index) in visibleTabs" :key="index" :label="tab.options.label" :disabled="tab.options.disabled" :name="tab.options.name">
                        <template v-for="(subWidget, swIdx) in tab.widgetList">
                            <template v-if="subWidget.category === 'container'">
                                <component :is="subWidget.type + '-widget-ly'" :widget="subWidget" :key="swIdx" :parent-list="tab.widgetList" :index-of-parent-list="swIdx" :parent-widget="widget" :design-state="designState"></component>
                            </template>
                            <template v-else>
                                <component :is="subWidget.type + '-widget-ly'" :field="subWidget" :key="swIdx" :parent-list="tab.widgetList" :index-of-parent-list="swIdx" :parent-widget="widget" :design-state="designState"></component>
                            </template>
                        </template>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </containers-wrapper-ly>
    </div>

</template>

<script setup>
    import {ref, onMounted, reactive, computed, nextTick, watch} from 'vue'
    import useCommon from '@/components/lyform-builder/lyform-design/useCommon'
    import { useBuilderStore } from "@/store/lyFormBuilder";

    const props = defineProps({
        widget: Object,
        parentWidget: Object,
        parentList: Array,
        indexOfParentList: Number,
        designState: {
            type: Boolean,
            default: false
        },
    })
    const builderStore = useBuilderStore()
    const selected = computed(() => {
        return props.designState&&props.widget.id === builderStore.selectedId
    })
    let {selectWidget} = useCommon()
    const customClass = computed(() => {
        return props.widget.options.customClass || ''
    })

    const activeTab =  ref('tab1')
    const activeTabName = ref("")

    const visibleTabs = computed(() => {
        return props.widget.tabs.filter(t => {
            return !t.options.hidden
        })
    })

    function onTabClick(tab) {
        if (!!props.widget.options.onTabClick) {
            let customFn = new Function('tab', props.widget.options.onTabClick)
            customFn(tab)
        }
    }

    function initActiveTab() {
        if ((props.widget.type === 'tab') && (props.widget.tabs.length > 0)) {
            //如果设置了active属性则展示active活动的，没有设置，则默认第一个选项卡激活状态
            let activePanes = props.widget.tabs.filter((t) => {
                return t.options.active === true
            })
            if (activePanes.length > 0) {
                activeTabName.value = activePanes[0].options.name
            } else {
                activeTabName.value = props.widget.tabs[0].options.name
            }
        }
    }
    function onContainerDragAdd(evt, subList) {
        const newIndex = evt.newIndex
        if (!!subList[newIndex]) {
            builderStore.setSelected( subList[newIndex] )
        }
        builderStore.emitHistoryChange()
    }
    function onContainerDragUpdate() {
        builderStore.emitHistoryChange()
    }
    function handleContainerMove(e) {
        return builderStore.handleWidgetMove(e)
    }

    onMounted(()=>{
        if(!props.designState){
            initActiveTab()
        }
    })


</script>

<style lang="scss" scoped>
    .lytab-container {
        margin: 2px;

        .form-widget-list {
            min-height: 28px;
        }

        ::v-deep(.el-tabs__content) {
            min-height: 28px;
        }
    }

    .lytab-container.selected {
        outline: 2px solid var(--el-color-primary) !important;
    }
</style>