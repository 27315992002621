<template>
    <div :class="{'ly-is-full':isFull}">
        <div class="tableSelect" ref="tableSelect">
            <el-form :model="formInline">
                <el-form-item label="姓名：">
                    <el-input v-model="formInline.name" maxlength="100" clearable placeholder="请输入姓名" @change="search" style="width:200px"></el-input>
                </el-form-item>
                <el-form-item label="生日：">
                    <el-date-picker style="width:350px" v-model="birthdayTimers" type="daterange" @change="birthdayTimeChange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                </el-form-item>
                <el-form-item label="状态：">
                    <el-select v-model="formInline.status" clearable filterable placeholder="请选择状态" style="width:200px;" @change="search">
                        <el-option
                            v-for="item in dictStore.data['button_whether_bool']"
                            :key="item.id"
                            :label="item.label"
                            :value="item.value" />
                    </el-select>
                </el-form-item>
                <el-form-item label="创建时间：" v-if="false">
                    <el-date-picker
                        style="width:350px"
                        v-model="timers"
                        type="datetimerange"
                        @change="timeChange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="" v-show="hasPermission(this.$route.name,'Search')"><el-button  @click="search" type="primary" icon="Search">查询</el-button></el-form-item>
                <el-form-item label=""><el-button  @click="handleEdit('','reset')" icon="Refresh">重置</el-button></el-form-item>
                <el-form-item label="" v-show="hasPermission(this.$route.name,'Create')"><el-button type="primary" icon="Plus" @click="handelAdd" >新增</el-button></el-form-item>
                <el-form-item label="" v-if="hasPermission(this.$route.name,'Delete') && true"><el-button @click="handleDelete" type="danger" :disabled="multiple" icon="Delete" >删除</el-button></el-form-item>
                <el-form-item label="" v-if="hasPermission(this.$route.name,'Export') && true"><el-button type="primary" @click="exportDataBackend" :disabled="loadingPage" icon="Download">导出</el-button></el-form-item>
            </el-form>
        </div>
        <div class="table">
            <el-table  :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="60" align="center"></el-table-column>
                <el-table-column type="index" width="60" align="center" label="序号">
                    <template #default="scope">
                        <span v-text="getIndex(scope.$index)"></span>
                    </template>
                </el-table-column>
                <el-table-column min-width="100" prop="avatar" label="头像" v-if="true">
                    <template #default="scope">
                        <el-image preview-teleported :src="scope.row.avatar" style="width: 30px;height:30px" :preview-src-list="[scope.row.avatar]" v-if="scope.row.avatar"></el-image>
                    </template>
                </el-table-column>
                <el-table-column min-width="100" prop="name" label="姓名" v-if="true"></el-table-column>
                <el-table-column min-width="100" prop="gender" label="性别" v-if="true">
                    <template #default="scope">
                        <span v-for="(item,index) in dictStore.data['gender']">
                            <span  v-if="scope.row.gender == item.value" >{{ item.label }}</span>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column min-width="100" prop="gender2" label="性别选择" v-if="false">
                    <template #default="scope">
                        <span v-for="(item,index) in dictStore.data['gender']">
                            <span  v-if="scope.row.gender2 == item.value" >{{ item.label }}</span>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column min-width="100" prop="age" label="年龄" v-if="true"></el-table-column>
                <el-table-column min-width="100" prop="birthday" label="生日" v-if="true"></el-table-column>
                <el-table-column min-width="100" prop="status" label="状态" v-if="true">
                    <template #default="scope">
                        <span v-for="(item,index) in dictStore.data['button_whether_bool']">
                            <el-tag  v-if="scope.row.status && !!JSON.parse(item.value)"  type="success">{{ item.label }}</el-tag>
                            <el-tag  v-else-if="!scope.row.status && !(!!JSON.parse(item.value))"  type="danger">{{ item.label }}</el-tag>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column min-width="100" prop="user" label="关联账号" v-if="true">
                    <template #default="scope">
                        {{scope.row.user_lyaudocode_name.name+":"+scope.row.user_lyaudocode_name.mobile}}
                    </template>
                </el-table-column>
                <el-table-column min-width="100" prop="file" label="附件" v-if="true">
                    <template #default="scope">
                        <div v-if="!!scope.row.file && scope.row.file.split(',').length<2">
  <el-link type="primary" @click="downloadFileURL(scope.row.file)">
    点击下载
  </el-link>
</div>
<div v-else>
  {{!!scope.row.file?scope.row.file.split(',').length:0 }}个
</div>
                    </template>
                </el-table-column>
                <el-table-column min-width="150" prop="create_datetime" label="创建时间"></el-table-column>
                <el-table-column label="操作" fixed="right" width="180">
                    <template #header>
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <div>操作</div>
                            <div @click="setFull">
                                <el-tooltip content="全屏" placement="bottom">
                                    <el-icon ><full-screen /></el-icon>
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                    <template #default="scope">
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'detail')" v-show="hasPermission(this.$route.name,'Retrieve')">详情</span>
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'edit')" v-show="hasPermission(this.$route.name,'Update')">编辑</span>
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'delete')" v-show="hasPermission(this.$route.name,'Delete')">删除</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <ly-dialog v-model="dialogVisible" :title="dialogTitle" :width="dialogWidth" :top="dialogTop" :fullscreen="fullscreen" :before-close="handleDialogClose">
            <el-form :model="formData" :rules="rules" ref="rulesForm" :disabled="formDisabled" :label-position="formLabelPosition" :label-width="formLabelWidth">
                <el-row :gutter="20">
                    <el-col :span="24?24:24">
                        <el-form-item label="头像" prop="avatar" v-if="true">
                            <ly-public-pictrue-single-upload v-model="formData.avatar" :disabled="isFormItemEditDisabled()" :round="false" :cropper="false"
                                title="" :show-file-list="true" :width="148" :height="148"
                                icon="plus" :compress="1" :aspect-ratio="NaN" :max-size="10">
                            </ly-public-pictrue-single-upload>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24?24:24">
                        <el-form-item label="姓名" prop="name" v-if="true">
                            <el-input v-model="formData.name" :disabled="isFormItemEditDisabled(true)" style="width: 100%;" placeholder="请输入姓名"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12?12:24">
                        <el-form-item label="性别" prop="gender" v-if="true">
                            <el-radio-group v-model="formData.gender" :disabled="isFormItemEditDisabled()">
                                <el-radio :label="convertStrNum(formData.gender,rditem.value)" v-for="(rditem,rdindex) in dictStore.data['gender']">{{ rditem.label }}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12?12:24">
                        <el-form-item label="性别选择" prop="gender2" v-if="true">
                            <el-select v-model="formData.gender2" :disabled="isFormItemEditDisabled(false)" clearable filterable placeholder="请选择性别选择" style="width: 100%;">
                                <el-option
                                    v-for="item in dictStore.data['gender']"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="convertStrNum(formData.gender2,item.value)" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12?12:24">
                        <el-form-item label="年龄" prop="age" v-if="true">
                            <el-input-number v-model="formData.age" :disabled="isFormItemEditDisabled()"  :min="0" :max="999999" style="width: 100%;" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12?12:24">
                        <el-form-item label="生日" prop="birthday" v-if="true">
                            <el-date-picker type="date" v-model="formData.birthday" :disabled="isFormItemEditDisabled()" clearable format="YYYY-MM-DD" value-format="YYYY-MM-DD" placeholder="请选择生日" style="width: 100%;">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24?24:24">
                        <el-form-item label="状态" prop="status" v-if="true">
                            <el-switch v-model="formData.status" :disabled="isFormItemEditDisabled()" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24?24:24">
                        <el-form-item label="关联账号" prop="user" v-if="true">
                            <el-select v-model="formData.user" :disabled="isFormItemEditDisabled()" clearable filterable placeholder="请选择关联账号" style="width: 100%;">
                                <el-option
                                    v-for="item in userLyAudoCodeAPIList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24?24:24">
                        <el-form-item label="附件" prop="file" v-if="true">
                            <ly-public-file-multiple-upload v-model="formData.file" :disabled="isFormItemEditDisabled(false)" accept="['.doc', '.docx', '.xls', '.xlsx']"
                                :multiple="true" :drag="true" :limit="5" tip="" :show-file-list="true"
                                :max-size="200" icon="plus" button-type="primary">
                                <el-icon class="el-icon--upload" v-if="true"><upload-filled /></el-icon>
                                <div class="el-upload__text" v-if="true">
                                拖拽文件到这 或 <em>点击上传</em>
                                </div>
                            </ly-public-file-multiple-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template v-slot:footer>
                <el-button @click="handleDialogClose">取消</el-button>
                <el-button @click="handleDialogSubmit" type="primary" :loading="dialogLoadingSave" v-if="dialogTitle!='详情'">确定</el-button>
            </template>
        </ly-dialog>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    </div>
</template>

<script>

    import {ajaxGet,ajaxPost,ajaxDelete,ajaxPut,ajaxPatch,ajaxDownloadExcel} from '@/api/request'
    // 列表
    const lyAutoCodeList = params => ajaxGet({url: `autocode/StudentManage/`,params})
    // 新增
    const lyAutoCodeAdd = params => ajaxPost({url: `autocode/StudentManage/`,params})
    // 编辑
    const lyAutoCodeEdit = params => ajaxPut({url: `autocode/StudentManage/`,params})
    // 删除
    const lyAutoCodeDelete = params => ajaxDelete({url: `autocode/StudentManage/`,params})
    // 导出
    const lyAutoCodeExport = params => ajaxDownloadExcel({url: `autocode/StudentManage/export/`,params})
    const userLyAudoCodeAPI = params => ajaxGet({url: `users/users/`,params})
    
    import {useDictStore} from "@/store/dict";
    
    import Pagination from "@/components/Pagination";
    import {dateFormats,getTableHeight,deepClone} from "@/utils/util";
    import LyDialog from "@/components/dialog/dialog";
    export default {
        name: "lyAutoCodeStudentManage",
        components: {LyDialog, Pagination},
        setup(){
            const dictStore = useDictStore()
            return { dictStore }
        },
        data(){
            return{
                isFull:false,
                tableHeight:500,
                loadingPage:false,
                // 选项框选中数组
                ids: [],
                // 选项框非单个禁用
                single: true,
                // 非多个禁用
                multiple: true,
                formInline:{
                    page: 1,
                    limit: 10,
                },
                pageparm: {
                    page: 1,
                    limit: 10,
                    total: 0
                },
                timers:[],
                tableData:[],
                //弹窗
                dialogVisible:false,
                dialogTitle:"",
                dialogLoadingSave:false,
                fullscreen:false,
                dialogWidth:"50%",
                dialogTop:"30px",
                formDisabled:false,
                formLabelPosition:"right",
                formLabelWidth:"auto",
                formData:{"avatar": "", "name": "", "gender": 0, "gender2": 0, "age": null, "birthday": null, "status": false, "user": "", "file": null},
                rules:{
                    name: [{ required: true, message: "姓名不能为空",trigger: 'blur'}],
                    gender2: [{ required: true, message: "性别选择不能为空",trigger: 'change'}],
                },
                userLyAudoCodeAPIList:[],
                birthdayTimers:[],
            }
        },
        methods:{
            // 表格序列号
            getIndex($index) {
                // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
                return (this.pageparm.page-1)*this.pageparm.limit + $index +1
            },
            setFull(){
                this.isFull=!this.isFull
                window.dispatchEvent(new Event('resize'))
            },
            //多选项框被选中数据
            handleSelectionChange(selection) {
                this.ids = selection.map(item => item.id);
                this.single = selection.length !== 1;
                this.multiple = !selection.length;
            },
            /** 批量删除按钮操作 */
            handleDelete(row) {
                const ids = this.ids;
                let vm = this
                vm.$confirm('是否确认删除选中的数据项?', "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(function() {
                    return lyAutoCodeDelete({id:ids}).then(res=>{
                        if(res.code == 2000) {
                            vm.$message.success(res.msg)
                            vm.getData()
                        } else {
                            vm.$message.warning(res.msg)
                        }
                    })
                })
            },
            convertStrNum(model,value){
                if(model === "" || model === null || model === undefined){
                    return value
                }
                else if(Object.prototype.toString.call(model)=== "[object String]"){
                    return value.toString()
                }
                else if(Object.prototype.toString.call(model) === "[object Number]"){
                    return value*1
                }else{
                    return value
                }
            },
            //判断单个组件编辑时是否禁用disabled参数为bool值
            isFormItemEditDisabled(disabled = false){
                if(disabled == "" || disabled == null || disabled == undefined){
                    return false
                }else if((disabled == true || disabled == 'True') && this.dialogTitle==='编辑'){
                    return true
                }
                return false
            },
            handelAdd() {
                this.dialogTitle = "添加"
                this.dialogVisible = true
                this.userLyAudoCodeAPIFunction()
            },
            handleDialogClose(){
                this.dialogTitle =""
                this.dialogVisible = false
                this.dialogLoadingSave = false
                this.formDisabled = false
                this.formData = {"avatar": "", "name": "", "gender": 0, "gender2": 0, "age": null, "birthday": null, "status": false, "user": "", "file": null}
            },
            handleDialogSubmit(){
                this.$refs['rulesForm'].validate((obj)=>{
                    if(obj) {
                        this.dialogLoadingSave=true
                        let param = {
                            ...this.formData
                        }
                        if(this.dialogTitle=="编辑"){
                            lyAutoCodeEdit(param).then(res=>{
                                this.dialogLoadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleDialogClose()
                                    this.getData()
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }else{
                            lyAutoCodeAdd(param).then(res=>{
                                this.dialogLoadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleDialogClose()
                                    this.getData()
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }

                    }
                })
                this.dialogLoadingSave=false
            },
            handleEdit(row,flag) {
                if(flag=='edit') {
                    this.dialogTitle = "编辑"
                    this.dialogVisible = true
                    this.formData = deepClone(row)
                    this.userLyAudoCodeAPIFunction()
                }
                else if(flag=='delete') {
                    let vm = this
                    vm.$confirm('您确定要删除选中的数据吗？',{
                        closeOnClickModal:false
                    }).then(res=>{
                        lyAutoCodeDelete({id:row.id}).then(res=>{
                            if(res.code == 2000) {
                                vm.$message.success(res.msg)
                                vm.search()
                            } else {
                                vm.$message.warning(res.msg)
                            }
                        })
                    }).catch(()=>{

                    })
                }else if(flag=='detail'){
                    this.dialogTitle = "详情"
                    this.dialogVisible = true
                    this.formDisabled = true
                    this.formData = deepClone(row)
                    this.userLyAudoCodeAPIFunction()
                }else if(flag=="reset"){
                    this.formInline = {
                        page:1,
                        limit: 10
                    }
                    this.pageparm={
                        page: 1,
                        limit: 10,
                        total: 0
                    }
                    this.timers = []
                    this.birthdayTimers=[]
                    this.getData()
                }
            },
            /**
             * 从URL里下载文件
            */
            // 下载文件
            downloadFileURL(url) {
                var a =document.createElement("a")
                var event = new MouseEvent('click')
                a.href = url
                a.target = "_blank"
                a.dispatchEvent(event)
            },
            //excel文件流下载
            downloadExcelBlob(res) {
                let fileName = new Date().getTime() +".xlsx"
                let dispositionStr = res.headers["content-disposition"];
                if (dispositionStr == null || dispositionStr === "") {

                }else{
                    // 获取文件名
                    let dispositionArr = dispositionStr.split(";");
                    fileName = decodeURIComponent(dispositionArr[1]);
                    fileName = fileName.split("=")[1];
                }
                const blob = new Blob([res.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                let href = window.URL.createObjectURL(blob); //下载链接
                let link = document.createElement("a")
                link.href = href
                link.download = fileName //下载后文件名
                document.body.appendChild(link);
                link.click(); //点击下载
                document.body.removeChild(link); //下载完成移除元素
                window.URL.revokeObjectURL(href);  //释放blob对象
                this.$message.success('导出成功')
            },
            //导出
            exportDataBackend() {
                let params = {}
                if(this.ids.length>0){
                    params = {
                        ids:this.ids,
                    }
                }
                this.loadingPage = true
                lyAutoCodeExport(params).then(res => {
                    this.loadingPage = false
                    this.downloadExcelBlob(res)
                })
            },
            callFather(parm) {
                this.formInline.page = parm.page
                this.formInline.limit = parm.limit
                this.getData()
            },
            search() {
                this.formInline.page = 1
                this.formInline.limit = 10
                this.getData()
            },
            //获取列表
            async getData() {
                this.loadingPage = true
                lyAutoCodeList(this.formInline).then(res => {
                     this.loadingPage = false
                     if(res.code ==2000) {
                         this.tableData = res.data.data
                         this.pageparm.page = res.data.page;
                         this.pageparm.limit = res.data.limit;
                         this.pageparm.total = res.data.total;
                     }
                })
            },
            timeChange(val){
                if (val) {
                    this.formInline.beginAt=dateFormats(val[0],'yyyy-MM-dd hh:mm:ss');
                    this.formInline.endAt=dateFormats(val[1],'yyyy-MM-dd hh:mm:ss');
                } else {
                    this.formInline.beginAt = null
                    this.formInline.endAt = null
                }
                this.search()
            },
            birthdayTimeChange(val) {
                if (val) {
                    this.formInline.birthday_beginAt=dateFormats(val[0],'yyyy-MM-dd');
                    this.formInline.birthday_endAt=dateFormats(val[1],'yyyy-MM-dd');
                } else {
                    this.formInline.birthday_beginAt = null
                    this.formInline.birthday_endAt = null
                }
                this.search()
            },
            // 计算搜索栏的高度
            listenResize() {
                this.$nextTick(() => {
                    this.getTheTableHeight()
                })
            },
            getTheTableHeight(){
                let tabSelectHeight = this.$refs.tableSelect?this.$refs.tableSelect.offsetHeight:0
                tabSelectHeight = this.isFull?tabSelectHeight - 110:tabSelectHeight
                this.tableHeight =  getTableHeight(tabSelectHeight)
            },
            userLyAudoCodeAPIFunction() {
                userLyAudoCodeAPI({page:1,limit:999}).then(res => {
                    if(res.code ==2000) {
                        this.userLyAudoCodeAPIList = res.data.data
                    }
                })
            },

        },
        created() {
            this.getData()
        },
        mounted() {
            // 监听页面宽度变化搜索框的高度
            window.addEventListener('resize', this.listenResize);
            this.$nextTick(() => {
                this.getTheTableHeight()
            })
        },

        unmounted() {
            // 页面销毁，去掉监听事件
            window.removeEventListener("resize", this.listenResize);
        },

    }
</script>

<style lang="scss" scoped>

</style>