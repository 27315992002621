<template>
    <static-content-wrapper-ly :field="field" :rules="rules" :design-state="designState"
                     :parent-widget="parentWidget" :parent-list="parentList" :index-of-parent-list="indexOfParentList">
        <el-divider ref="lyfieldEditor" :direction="field.options.direction" :border-style="field.options.borderStyle" :content-position="field.options.contentPosition">{{field.options.label}}</el-divider>
    </static-content-wrapper-ly>
</template>

<script setup>
    import {ref, onMounted, reactive, computed, watch} from 'vue'
    import { useBuilderStore } from "@/store/lyFormBuilder";

    const props = defineProps({
        field: Object,
        parentWidget: Object,
        parentList: Array,
        indexOfParentList: Number,
        designState: {
            type: Boolean,
            default: false
        },
    })
    const builderStore = useBuilderStore()
    const fieldModel = ref(null)
    let rules = ref([])
    const inputType = computed(() => {
        if (props.field.options.type === 'number') {
          return 'text'  //当input的type设置为number时，如果输入非数字字符，则v-model拿到的值为空字符串，无法实现输入校验！故屏蔽之！！
        }
        return props.field.options.type
    })
    const widgetSize = computed(() => {
        return props.field.options.size || 'default'
    })

    function initFieldModel() {
        if (!props.field.formItemFlag) {
            return
        }
        fieldModel.value = props.field.options.defaultValue
    }
    watch(()=>props.field.options.defaultValue,(n,o)=>{
        fieldModel.value = n
    })

    onMounted(()=>{
        initFieldModel()
    })



</script>

<style lang="scss" scoped>
</style>