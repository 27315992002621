<template>
    <static-content-wrapper-ly :field="field" :rules="rules" :design-state="designState"
                     :parent-widget="parentWidget" :parent-list="parentList" :index-of-parent-list="indexOfParentList">
        <el-text ref="lyfieldEditor" :tag="field.options.tag"
                 :style="[`text-align:${field.options.labelPosition}`,!!field.options.fontSize ? `font-size: ${field.options.fontSize};`: '']"
                 :type="field.options.type" :truncated="field.options.truncated">
            {{field.options.textContent}}
        </el-text>
    </static-content-wrapper-ly>
</template>

<script setup>
    import {ref, onMounted, reactive, computed, watch} from 'vue'
    import { useBuilderStore } from "@/store/lyFormBuilder";

    const props = defineProps({
        field: Object,
        parentWidget: Object,
        parentList: Array,
        indexOfParentList: Number,
        designState: {
            type: Boolean,
            default: false
        },
    })
    const builderStore = useBuilderStore()
    const fieldModel = ref(null)
    let rules = ref([])

    const widgetSize = computed(() => {
        return props.field.options.size || 'default'
    })

    function initFieldModel() {
        if (!props.field.formItemFlag) {
            return
        }
        fieldModel.value = props.field.options.defaultValue
    }
    watch(()=>props.field.options.defaultValue,(n,o)=>{
        fieldModel.value = n
    })

    onMounted(()=>{
        initFieldModel()
    })



</script>

<style lang="scss" scoped>
</style>