export const lyGenerateCode = function(formJson) {
  let formJsonStr = JSON.stringify(formJson)
  return `<template>
  <div>
     <lyDialog v-model="dialogVisible" :title="dialogTitleMap[currentMode]" width="60%" :before-close="handleClose" :fullscreen="fullscreen" :loading="dialogLoading" @closed="closed">
        <lyFormRender :form-json="formJson" :form-data="formData" ref="lyFormBuilderRef"></lyFormRender>
        <template #footer>
            <el-button @click="handleClose">取消</el-button>
            <el-button type="primary" @click="submitForm" :loading="loadingSave" v-if="currentMode!='detail'">确定</el-button>
        </template>
     </lyDialog>
  </div>
</template>

<script setup>
  import { ref, reactive ,onMounted ,watch} from 'vue'
  import { ElMessage } from 'element-plus'
  import lyDialog from '@/components/dialog/dialog'
  import lyFormRender from '@/components/lyform-builder/lyform-render/index'
  //此处导入新增、编辑的API接口如下所示(根据实际情况替换)
  import {lyadminproAdd,lyadminproEdit} from '@/api/api'
  
  const emit = defineEmits(['refreshData','closed'])
  
  let dialogVisible = ref(false)
  let dialogLoading = ref(false)
  let loadingSave = ref(false)
  let dialogTitle = ref('')
  let currentMode = ref('add')
  let dialogTitleMap = {
      add: '新增',
      edit: '编辑',
      detail: '详情'
  }
  
  let fullscreen = ref(false)
  
  let formJson = reactive(${formJsonStr})
  let formData = reactive({})
  let lyFormBuilderRef = ref(null)
  
  function handleClose() {
      dialogVisible.value = false
      dialogLoading.value = false
      loadingSave.value = false
      currentMode.value = 'add'
      formData = {}
  }
  
  function closed() {
      emit('closed')
  }
  
  function lymoduleFn(item,mode) {
      currentMode.value = mode;
      if(currentMode.value == "detail"){
          disableForm()
      }
      dialogVisible.value=true
      if(item){
          formData = Object.assign(formData, item)
      }
  }
  
  function disableForm(){
      if(formJson.formConfig){
          formJson.formConfig.disabled = true
      }
  }

  const submitForm = () => {
      lyFormBuilderRef.value.getFormData().then(formData => {
          //console.log(JSON.stringify(formData))
          //提交后端新增/编辑代码(根据实际情况替换API)
          if(formData){
              loadingSave.value=true
              let param = {
                  ...formData
              }
              if(currentMode.value == 'add'){
                  lyadminproAdd(param).then(res=>{
                      loadingSave.value=false
                      if(res.code ==2000) {
                          ElMessage.success(res.msg)
                          handleClose()
                          emit('refreshData')
                      } else {
                          ElMessage.warning(res.msg)
                      }
                  })
              }else{
                  lyadminproEdit(param).then(res=>{
                      loadingSave.value=false
                      if(res.code ==2000) {
                          ElMessage.success(res.msg)
                          handleClose()
                          emit('refreshData')
                      } else {
                          ElMessage.warning(res.msg)
                      }
                  })
              }
          }
            
      }).catch(error => {
            ElMessage.error(error)
      })
  }
  
  onMounted(()=>{
      
  })
  
  defineExpose({ lymoduleFn })
  
</script>

<style scoped>

</style>
`
}