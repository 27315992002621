<template>
    <div>
        <el-form size="small" label-position="left" label-width="100px" class="lywidgetsetting" @submit.prevent>
            <el-collapse v-model="lyCollapseActiveList" class="lyformsetting-collapse">
                <el-collapse-item name="lyws01" title="组件属性">
                    <el-form-item label="字段标识">
                        <el-input  v-model="optionModel.name" @change="handleChange"></el-input>
                    </el-form-item>
                    <el-form-item label="隐藏">
                        <el-switch v-model="optionModel.hidden" @change="handleChange"></el-switch>
                    </el-form-item>
                    <el-form-item label="禁用">
                        <el-switch v-model="optionModel.disabled" @change="handleChange"></el-switch>
                    </el-form-item>
                    <el-form-item label="标签文本">
                        <el-input v-model="optionModel.label"  style="width: 100%" @change="handleChange"></el-input>
                    </el-form-item>
                    <el-form-item label="标签宽度">
                        <el-input v-model="optionModel.labelWidth"  style="width: 100%" @change="handleChange"></el-input>
                    </el-form-item>
                    <el-form-item label="隐藏字段标签">
                        <el-switch v-model="optionModel.labelHidden" @change="handleChange"></el-switch>
                    </el-form-item>
                    <el-form-item label="必填字段">
                        <el-switch v-model="optionModel.required" @change="handleChange"></el-switch>
                    </el-form-item>
                    <el-form-item label="必填校验提示">
                        <el-input v-model="optionModel.requiredHint"  style="width: 100%" @change="handleChange"></el-input>
                    </el-form-item>
                    <customClassLy :selected-widget="selectedWidget" :option-model="optionModel"></customClassLy>
                    <el-form-item label-width="0">
                            <el-divider>上传参数设置</el-divider>
                    </el-form-item>
                    <el-form-item label="是否可裁剪">
                        <el-switch v-model="optionModel.cropper" @change="handleChange"></el-switch>
                    </el-form-item>
                    <el-form-item label="是否圆形">
                        <el-switch v-model="optionModel.round" @change="handleChange"></el-switch>
                    </el-form-item>
                    <el-form-item label="提示ICON">
                        <el-input v-model="optionModel.uploadIcon"  style="width: 100%" @change="handleChange"></el-input>
                    </el-form-item>
                    <el-form-item label="提示内容">
                        <el-input v-model="optionModel.uploadTitle"  style="width: 100%" @change="handleChange"></el-input>
                    </el-form-item>
                    <el-form-item label="裁剪压缩率">
                        <el-input-number v-model="optionModel.compress" :min="0" :max="1" style="width: 100%" @change="handleChange"></el-input-number>
                    </el-form-item>
                    <el-form-item label="固定剪裁比例">
                        <el-input v-model="optionModel.aspectRatio"  style="width: 100%" placeholder="比如1/1, 4/3, 16/9" @change="handleChange"></el-input>
                    </el-form-item>
                    <el-form-item label="大小限制(MB)">
                        <el-input-number v-model="optionModel.fileMaxSize" :min="1" :max="1000" style="width: 100%" @change="handleChange"></el-input-number>
                    </el-form-item>
                    <el-form-item label="组件宽度(px)">
                        <el-input-number v-model="optionModel.uploadWidth" :min="1" :max="10000" style="width: 100%" @change="handleChange"></el-input-number>
                    </el-form-item>
                    <el-form-item label="组件高度(px)">
                        <el-input-number v-model="optionModel.uploadHight" :min="1" :max="10000" style="width: 100%" @change="handleChange"></el-input-number>
                    </el-form-item>
                </el-collapse-item>
                <el-collapse-item name="lyws03" title="CRUD属性">
                    <template #title>
                        <div style="display: flex;align-items: center;">
                            CRUD属性
                            <el-tooltip effect="dark" content="后端自动生成CRUD代码，用于控制是否列表显示、可查询、可空、可编辑等属性" placement="top-start">
                                <el-icon><QuestionFilled /></el-icon>
                            </el-tooltip>
                        </div>
                    </template>
                    <crudCommonLy :selected-widget="selectedWidget" :option-model="optionModel"></crudCommonLy>
                </el-collapse-item>
            </el-collapse>
        </el-form>
    </div>
</template>

<script setup>
    import {ref, onMounted, reactive, computed, watch} from 'vue'
    import { useBuilderStore } from "@/store/lyFormBuilder";
    import validationCommonLy from "@/components/lyform-builder/lysetting-pannel/lywidget-common/validation-common-ly"
    import customClassLy from "@/components/lyform-builder/lysetting-pannel/lywidget-common/custom-class-ly"
    import crudCommonLy from "@/components/lyform-builder/lysetting-pannel/lywidget-common/crud-common-ly"
    const props = defineProps({
        selectedWidget: Object,
        optionModel: Object,
    })
    const builderStore = useBuilderStore()
    function handleChange(newValue) {
         builderStore.saveCurrentHistoryStep()
    }
    let lyCollapseActiveList =  ref(['lyws01','lyws02','lyws03'])
    const formSizes = ref([
        {label: 'default', value: ''},
        {label: 'large', value: 'large'},
        {label: 'small', value: 'small'},
    ])
    function showEventCollapse(){
        if (builderStore.builderConfig['showEventCollapse'] === undefined) {
          return true
        }
        return !!builderStore.builderConfig['showEventCollapse']
    }
    function editFormEventHandler(flag){

    }

</script>

<style lang="scss" scoped>
    .lywidgetsetting{
        ::v-deep(.el-form-item__label) {
          font-size: 13px;
          overflow: hidden;
          white-space: nowrap;
        }
        .lyformsetting-collapse{
            ::v-deep(.el-collapse-item__header) {
                font-weight: bold;
            }
        }
    }

</style>