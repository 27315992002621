export default {
	login: {
		loginInTitle: '用户登录',
		loginAccount: '请输入账号',
		loginPWD: '请输入密码',
		code: '验证码',
		rememberMe: '记住密码',
		AccountError: '请输入用户名',
		PWError: '请输入密码',
		codeError: '请输入验证码',
		login: '登录',
		admin: '管理员',
		user: '用户',
		loginOther: '其他登录方式',
		accountLogin: '账号登录',
		wechatLoginTitle: '二维码登录',
		wechatLoginMsg: '请使用微信扫一扫登录(测试无实际登录)',
		wechatLoginSubMsg: '模拟3秒钟后自动扫描',
		wechatLoginResult: '已扫描',
		wechatLoginSubResult: '请在设备中点击授权登录',
		describe:'临潭县民事村办管理平台',
	}
}