<template>
    <div>
        <el-form size="small" label-position="left" label-width="100px" class="lywidgetsetting" @submit.prevent>
            <el-collapse v-model="lyCollapseActiveList" class="lyformsetting-collapse">
                <el-collapse-item name="lyws01" title="组件属性">
                    <el-form-item label="字段标识">
                        <el-input  v-model="optionModel.name" @change="handleChange"></el-input>
                    </el-form-item>
                    <el-form-item label="隐藏">
                        <el-switch v-model="optionModel.hidden" @change="handleChange"></el-switch>
                    </el-form-item>
                    <customClassLy :selected-widget="selectedWidget" :option-model="optionModel"></customClassLy>
                    <el-form-item label="选项卡设置"></el-form-item>
                    <el-form-item label-width="0" class="panes-setting">
                        <draggable tag="ul" :list="selectedWidget.tabs" item-key="id" v-bind="{group:'panesGroup', ghostClass: 'ghost', handle: '.drag-option'}">
                            <template #item="{ element: tpItem, index: tpIdx }">
                                <li class="col-item">
                                    <el-icon class="drag-option" size="18"><Rank /></el-icon>
                                    <el-input type="text" v-model="tpItem.options.label" style="width: 155px"></el-input>
                                    <el-button circle plain size="small" type="danger" @click="deleteTabPane(selectedWidget, tpIdx)" icon="SemiSelect" class="col-delete-button"></el-button>
                                </li>
                            </template>
                        </draggable>
                        <div>
                            <el-button link type="primary" @click="addTabPane(selectedWidget)" icon="plus">增加选项卡页</el-button>
                        </div>
                    </el-form-item>
                </el-collapse-item>
                <el-collapse-item name="lyws02" title="事件属性" v-if="showEventCollapse">
                    <el-form-item label="onTabClick" label-width="150px">
                        <el-button type="info" icon="edit" plain round @click="editFormEventHandler('onTabClick',['tab'])">编写代码</el-button>
                    </el-form-item>
                </el-collapse-item>
            </el-collapse>
            <lyDialog v-model="showFormEventDialog" title="组件事件" width="60%" top="20px" :before-close="handleFormEventHandlerClose">
                <div>
                    <el-alert :title="currentEventNameTitle" type="info" :closable="false" />
                    <lyCodeEditor ref="lyFormEditCss" v-model="formEventCode" mode="javascript" height="600" :read-only="false"></lyCodeEditor>
                    <el-alert title="}"  :closable="false" type="info"></el-alert>
                </div>
                <template v-slot:footer>
                    <el-button @click="handleFormEventHandlerClose" size="default">关闭</el-button>
                    <el-button type="primary" @click="saveFormEvent" size="default">确定</el-button>
                </template>
            </lyDialog>
        </el-form>
    </div>
</template>

<script setup>
    import {ref, onMounted, reactive, computed, watch,defineAsyncComponent} from 'vue'
    import customClassLy from "@/components/lyform-builder/lysetting-pannel/lywidget-common/custom-class-ly"
    import { ElMessage } from 'element-plus'
    import { useBuilderStore } from "@/store/lyFormBuilder";
    import lyDialog from '@/components/dialog/dialog'
    const lyCodeEditor = defineAsyncComponent(() => import('@/components/lyform-builder/code-editor'));

    const props = defineProps({
        selectedWidget: Object,
        optionModel: Object,
    })
    const builderStore = useBuilderStore()
    function handleChange(newValue) {
         builderStore.saveCurrentHistoryStep()
    }
    let lyCollapseActiveList =  ref(['lyws01','lyws02','lyws03'])
    function showEventCollapse(){
        if (builderStore.builderConfig['showEventCollapse'] === undefined) {
          return true
        }
        return !!builderStore.builderConfig['showEventCollapse']
    }

    function addTabPane(curTabs) {
        builderStore.addTabPaneOfTabs(curTabs)
        builderStore.emitHistoryChange()
    }

    function deleteTabPane(curTabs, tpIdx) {
        if (curTabs.tabs.length === 1) {
            ElMessage.warning("删除失败，至少留一个选项卡")
            return
        }
        builderStore.deleteTabPaneOfTabs(curTabs, tpIdx)
        builderStore.emitHistoryChange()
    }

    let currentEventName = ref("")//编辑选中当前事件名称
    let currentEventNameTitle = ref("")//编辑框提示事件函数全名
    let showFormEventDialog = ref(false)
    let formEventCode = ref("")
    function editFormEventHandler(flag,eventParamsName){//flag事件名、eventParamsName事件回调参数名使用数组[]
        currentEventName.value = flag
        currentEventNameTitle.value = `${props.optionModel.name}.${flag}(${eventParamsName.join(', ')}) {`
        formEventCode.value = props.selectedWidget.options[flag] || ''
        showFormEventDialog.value = true
    }
    function handleFormEventHandlerClose(){
        showFormEventDialog.value = false
    }
    function saveFormEvent(){
        builderStore.selectedWidget.options[currentEventName.value] = formEventCode.value
        builderStore.saveCurrentHistoryStep()
        showFormEventDialog.value = false
    }


</script>

<style lang="scss" scoped>
    .lywidgetsetting{
        ::v-deep(.el-form-item__label) {
          font-size: 13px;
          overflow: hidden;
          white-space: nowrap;
        }
        .lyformsetting-collapse{
            ::v-deep(.el-collapse-item__header) {
                font-weight: bold;
            }
        }
        .col-item {
            list-style: none;
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            .col-span-title {
                display: inline-block;
                font-size: 13px;
                width: 120px;
            }

            .col-delete-button {
                margin-left: 6px;
            }
        }

        .panes-setting {
            ul {
                padding-inline-start: 0;
                padding-left: 0; /* 重置IE11默认样式 */
                margin: 0;
            }

            .drag-option {
                margin-right: 5px;
                cursor: move;
            }

            li.ghost {
                background: #fff;
                border: 2px dotted var(--el-color-primary);
            }
        }
    }

</style>