<template>
    <form-item-wrapper-ly :field="field" :rules="rules" :design-state="designState"
                     :parent-widget="parentWidget" :parent-list="parentList" :index-of-parent-list="indexOfParentList">
        <el-input ref="lyfieldEditor" v-model="fieldModel"
              :disabled="field.options.disabled" :readonly="field.options.readonly"
              :size="widgetSize"
              :type="inputType"
              :show-password="field.options.showPassword"
              :placeholder="field.options.placeholder"
              :clearable="field.options.clearable"
              :minlength="field.options.minLength" :maxlength="field.options.maxLength"
              :show-word-limit="field.options.showWordLimit"
              :prefix-icon="field.options.prefixIcon" :suffix-icon="field.options.suffixIcon"
              @focus="handleFocusCustomEvent" @blur="handleBlurCustomEvent" @input="handleInputCustomEvent"
              @change="handleChangeEvent">
            <template #prepend v-if="field.options.prependSlot">
                {{field.options.prependSlot}}
            </template>
            <template #append v-if="field.options.appendSlot">
                {{field.options.appendSlot}}
            </template>
        </el-input>
    </form-item-wrapper-ly>
</template>

<script setup>
    import {ref, onMounted, reactive, computed, watch, inject} from 'vue'
    import { useBuilderStore } from "@/store/lyFormBuilder";
    import useCommon from '@/components/lyform-builder/lyform-design/useCommon'

    const props = defineProps({
        field: Object,
        parentWidget: Object,
        parentList: Array,
        indexOfParentList: Number,
        designState: {
            type: Boolean,
            default: false
        },
    })
    const builderStore = useBuilderStore()
    const fieldModel = ref(null)
    let rules = ref([])
    const inputType = computed(() => {
        if (props.field.options.type === 'number') {
          return 'text'  //当input的type设置为number时，如果输入非数字字符，则v-model拿到的值为空字符串，无法实现输入校验！故屏蔽之！！
        }
        return props.field.options.type
    })
    const widgetSize = computed(() => {
        return props.field.options.size || 'default'
    })
    function handleFocusCustomEvent(event) {
        if (!!props.field.options.onFocus) {
            let customFn = new Function('event', props.field.options.onFocus)
            customFn(event)
        }
    }
    function handleBlurCustomEvent(event) {
        if (!!props.field.options.onBlur) {
            let customFn = new Function('event', props.field.options.onBlur)
            customFn(event)
        }
    }
    function handleInputCustomEvent(value) {
        if (!!props.field.options.onInput) {
            let customFn = new Function('value', props.field.options.onInput)
            customFn(value)
        }
    }
    let {createFieldRules,getPropName,handleFieldValidation} = useCommon()
    const globalFormModel = inject("globalFormModel",null)
    function updateFormModel(value) {
        if (!!props.designState) {
            return
        }
        let keyName = getPropName(props)
        globalFormModel.value[keyName] = value
    }
    function handleChangeEvent(value) {
        updateFormModel(value)
        handleFieldValidation(getPropName(props))
        if (!!props.field.options.onChange) {
            let customFn = new Function('value', props.field.options.onChange)
            customFn(value)
        }
    }

    function initFieldModel() {
        if (!props.field.formItemFlag) {
            return
        }
        if(!(!!globalFormModel)){
            fieldModel.value = props.field.options.defaultValue
        }else if(globalFormModel.value[props.field.options.name]=== undefined && props.field.options.defaultValue !== undefined){
            fieldModel.value = props.field.options.defaultValue
        }else if(globalFormModel.value[props.field.options.name] === undefined){
            globalFormModel.value[props.field.options.name] = null
        }else {
            fieldModel.value = globalFormModel.value[props.field.options.name]
        }
    }
    watch(()=>props.field.options.defaultValue,(n,o)=>{
        fieldModel.value = n
    })

    onMounted(()=>{
        initFieldModel()
        createFieldRules(props.field,rules.value)
    })



</script>

<style lang="scss" scoped>

</style>