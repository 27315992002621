<!--
/**
 * author: lybbn
 * program django-vue-lyadmin
 * email: 1042594286@qq.com
 * website: http://www.lybbn.cn
 * date: 2022.11.17
 * remark: 如果要分发django-vue-lyadmin源码或其中组件等，需在本文件顶部保留此文件头信息！！！
 */
-->
<template>
    <div>
        <LyFormBuilder ref="lyformBuilder"></LyFormBuilder>
    </div>
</template>

<script setup>
    import {ref} from 'vue'
    import LyFormBuilder from '@/components/lyform-builder/lyFormBuilder'
    const lyformBuilder = ref()
    function setFull(){
        console.log(lyformBuilder)
        lyformBuilder.value.setFull()
    }
    defineExpose({
        setFull
    })
</script>

<style lang="scss" scoped>

</style>