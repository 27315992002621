<template>
	<div class="login_bg">
		<div class="login_adv" style="background-image: url(static/img/auth_banner.jpg);">
			<div class="login_adv__title">
				<h2>临潭县民事村办平台</h2>
				<p>后台管理系统 By:甘肃云山文化传媒</p>
				<!-- <p>{{ $t('login.describe') }}</p> -->
			</div>
			<!-- <div class="login_adv__mask"></div> -->
			<div class="login_adv__bottom">
				Copyright © 2022 甘肃云山传媒 All rights reserved.
			</div>
		</div>
		<div class="login_main">
			<!-- <img src="static/img/index-banner-1（756x630px）.jpg" alt="" style="width:100%"> -->
			<div class="login_config">
				<el-button :icon="siteThemeStore.siteTheme == 'dark'?'sunny':'moon'" circle type="info" @click="setSiteTheme"></el-button>
				<el-dropdown trigger="click" placement="bottom-end" @command="changeLang" style="margin-left: 10px">
					<el-button circle>
						<svg preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" width="1.2em" height="1.2em" data-v-12008bb2=""><path fill="currentColor" d="m18.5 10l4.4 11h-2.155l-1.201-3h-4.09l-1.199 3h-2.154L16.5 10h2zM10 2v2h6v2h-1.968a18.222 18.222 0 0 1-3.62 6.301a14.864 14.864 0 0 0 2.336 1.707l-.751 1.878A17.015 17.015 0 0 1 9 13.725a16.676 16.676 0 0 1-6.201 3.548l-.536-1.929a14.7 14.7 0 0 0 5.327-3.042A18.078 18.078 0 0 1 4.767 8h2.24A16.032 16.032 0 0 0 9 10.877a16.165 16.165 0 0 0 2.91-4.876L2 6V4h6V2h2zm7.5 10.885L16.253 16h2.492L17.5 12.885z"></path></svg>
					</el-button>
					<template #dropdown>
						<el-dropdown-menu>
							<el-dropdown-item v-for="item in lang" :key="item.value" :command="item" :class="{'lydpselected':language==item.value}">{{item.name}}</el-dropdown-item>
						</el-dropdown-menu>
					</template>
				</el-dropdown>
			</div>
			<div class="login-form">
				<div class="login-header">
					<div class="logo">
						<img :alt="config.APP_NAME" src="static/img/logo.png">
						<label>{{config.APP_NAME}}</label>
					</div>
				</div>
				<el-tabs>
					<el-tab-pane :label="$t('login.accountLogin')" lazy>
						<modulePasswordForm></modulePasswordForm>
					</el-tab-pane>
				</el-tabs>
				<!-- <template v-if="config.SHOW_LOGIN_OAUTH">
					<el-divider>{{ $t('login.loginOther') }}</el-divider>
					<div class="login-oauth">
						<el-button type="success" circle @click="wechatLogin">
							<template #icon>
								<svg-icon icon-class="lyicon-wechat" style="font-size: 18px;"></svg-icon>
							</template>
						</el-button>
					</div>
				</template> -->
			</div>
		</div>
	</div>
	<el-dialog v-model="showWechatLogin" :title="$t('login.wechatLoginTitle')" :width="400" destroy-on-close>
		<div class="qrCodeLogin">
			<lyQrcode class="qrCode" :text="WechatLoginCode" :size="200"></lyQrcode>
			<p class="msg">{{$t('login.wechatLoginMsg')}}</p>
			<div class="qrCodeLogin-result" v-if="isWechatLoginResult">
				<el-result icon="success" :title="$t('login.wechatLoginResult')" :sub-title="$t('login.wechatLoginSubResult')"></el-result>
			</div>
		</div>
	</el-dialog>
</template>

<script setup>
	import {ref, onMounted,watch,computed } from 'vue'
	import {useMutitabsStore} from "@/store/mutitabs";
	import {useSiteThemeStore} from "@/store/siteTheme";
	import i18n from '@/locales'
	import config from "@/config"
	import modulePasswordForm from './components/modulePasswordForm'
	import lyQrcode from "@/components/qrcode/lyqrcode";

	const mutitabsstore = useMutitabsStore()
	const siteThemeStore = useSiteThemeStore()
	const { t } = i18n.global

	let language = ref(siteThemeStore.language)
	let lang =  [
		{
			name: '简体中文',
			value: 'zh-cn',
		},
		{
			name: 'English',
			value: 'en',
		}
	]

	let WechatLoginCode = ref("")
	let showWechatLogin = ref(false)
	let isWechatLoginResult = ref(false)

	//设置主题
	function setSiteTheme(){
		if(siteThemeStore.siteTheme=='light'){
			siteThemeStore.setSiteTheme('dark')
		}else{
			siteThemeStore.setSiteTheme('light')
		}
	}

	//设置语言
	function changeLang(command){
		language.value = command.value
		siteThemeStore.setLanguage(command.value)
	}

	function wechatLogin(){
		showWechatLogin.value = true
		WechatLoginCode.value = "lyadmin-wechatlogin-" + new Date().getTime()
		isWechatLoginResult.value = false
		setTimeout(()=>{
			isWechatLoginResult.value = true
		},3000)
	}

	onMounted(()=>{
		//动态添加该页面meta viewport 手机适配
        if(document.querySelector("meta[name='viewport']")){
            document.querySelector("meta[name='viewport']")["content"] = "width=device-width,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0,user-scalable=no"
        }
	})

</script>

<style lang="scss" scoped>
	.logo{color:#e6130e;}
	::v-deep(.lydpselected){background-color: var(--el-dropdown-menuItem-hover-fill);color: var(--el-dropdown-menuItem-hover-color);}
	.login_bg {width: 100%;height: 100%;background: var(--el-bg-color);display: flex;}
	.login_adv {width: 33.3%;background-color: #3C444D;background-size: cover;background-position: center center;background-repeat: no-repeat;position: relative;}
	.login_adv__title {color: #fff;padding: 40px;position: absolute;top:0px;left:0px;right: 0px;z-index: 2;}
	.login_adv__title h2 {font-size: 30px;}
	.login_adv__title h4 {font-size: 18px;margin-top: 10px;font-weight: normal;}
	.login_adv__title p {font-size: 16px;margin-top:10px;line-height: 1.8;color: rgba(255,255,255,0.6);}
	.login_adv__title div {margin-top: 10px;display: flex;align-items: center;}
	.login_adv__title div span {margin-right: 15px;}
	.login_adv__title div i {font-size: 40px;}
	.login_adv__title div i.add {font-size: 20px;color: rgba(255,255,255,0.6);}
	.login_adv__bottom {position: absolute;left:0px;right: 0px;bottom: 0px;color:#fff;padding: 40px;z-index: 3;}
	.login_adv__mask {position: absolute;top:0px;left:0px;right: 0px;bottom: 0px;background: rgba(0,0,0,0.5);z-index: 1;}

	.login_main {flex: 1;overflow: auto;display:flex;}
	.login-form {width: 400px;margin: auto;padding:20px 0;}
	.login-header {margin-bottom: 40px;}
	.login-header .logo {display: flex;align-items: center;}
	.login-header .logo img {width: 40px;height: 40px;vertical-align: bottom;margin-right: 10px;}
	.login-header .logo label {font-size: 26px;font-weight: bold;}
	.login-oauth {display: flex;justify-content:space-around;}
	.login-form .el-divider {margin-top:40px;}

	.login-form {}
	.login-form{::v-deep(.el-tabs) .el-tabs__header {margin-bottom: 25px;}}
	.login-form{::v-deep(.el-tabs) .el-tabs__header .el-tabs__item {font-size: 14px;}}

	.login-form{::v-deep(.login-forgot) {text-align: right;}}
	.login-form{::v-deep(.login-forgot) a {color: var(--el-color-primary);}}
	.login-form{::v-deep(.login-forgot) a:hover {color: var(--el-color-primary-light-3);}}
	.login-form{::v-deep(.login-reg) {font-size: 14px;color: var(--el-text-color-primary);}}
	.login-form{::v-deep(.login-reg) a {color: var(--el-color-primary);}}
	.login-form{::v-deep(.login-reg) a:hover {color: var(--el-color-primary-light-3);}}

	.login_config {position: absolute;top:20px;right: 20px;}

	.login-form{::v-deep(.login-msg-yzm) {display: flex;width: 100%;}}
	.login-form{::v-deep(.login-msg-yzm) .el-button {margin-left: 10px;--el-button-size:42px;}}

	.qrCodeLogin {text-align: center;position: relative;padding: 20px 0;}
	.qrCodeLogin img.qrCode {background: var(--el-bg-color);padding:20px;border-radius:10px;}
	.qrCodeLogin p.msg {margin-top: 15px;}
	.qrCodeLogin .qrCodeLogin-result {position: absolute;top:0;left:0;right: 0;bottom: 0;text-align: center;background: var(--el-mask-color);}
	@media (max-width: 1200px){
		.login-form {width: 340px;}
	}
	@media (max-width: 1000px){
		.login_main {display: block;}
		.login_main .login_config {position: static;padding:20px 20px 0 20px;text-align: right;}
		.login_adv {display: none;}
	}
</style>