<template>
    <div :class="{'ly-is-full':isFull}">
        <div class="tableSelect" ref="tableSelect">
            <el-form :model="formInline">
                <el-form-item label="处理状态：">
                    <el-select v-model="formInline.status" clearable filterable placeholder="请选择处理状态" style="width:200px;" @change="search">
                        <el-option
                            v-for="item in dictStore.data['state']"
                            :key="item.id"
                            :label="item.label"
                            :value="item.value" />
                    </el-select>
                </el-form-item>
                <el-form-item label="事件类型：">
                    <el-select v-model="formInline.type" clearable filterable placeholder="请选择事件类型" style="width:200px;" @change="search">
                        <el-option
                            v-for="item in dictStore.data['thing_type']"
                            :key="item.id"
                            :label="item.label"
                            :value="item.value" />
                    </el-select>
                </el-form-item>
                <el-form-item label="说事人：">
                    <el-input v-model="formInline.user" maxlength="100" clearable placeholder="请输入说事人" @change="search" style="width:200px"></el-input>
                </el-form-item>
                <el-form-item label="紧急情况：">
                    <el-select v-model="formInline.jinji" clearable filterable placeholder="请选择紧急情况" style="width:200px;" @change="search">
                        <el-option
                            v-for="item in dictStore.data['jinji']"
                            :key="item.id"
                            :label="item.label"
                            :value="item.value" />
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="向其它部门推送：">
                    <el-input v-model="formInline.push" maxlength="100" clearable placeholder="请输入向其它部门推送" @change="search" style="width:200px"></el-input>
                </el-form-item> -->
                <el-form-item label="满意度：">
                    <el-select v-model="formInline.good" clearable filterable placeholder="请选择满意度" style="width:200px;" @change="search">
                        <el-option
                            v-for="item in dictStore.data['good']"
                            :key="item.id"
                            :label="item.label"
                            :value="item.value" />
                    </el-select>
                </el-form-item>
                <el-form-item label="地区名称：">
                    <el-input v-model="formInline.addName" maxlength="100" clearable placeholder="请输入地区名称" @change="search" style="width:200px"></el-input>
                </el-form-item>
                <el-form-item label="创建时间：" v-if="false">
                    <el-date-picker
                        style="width:350px"
                        v-model="timers"
                        type="datetimerange"
                        @change="timeChange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="" v-show="hasPermission(this.$route.name,'Search')"><el-button  @click="search" type="primary" icon="Search">查询</el-button></el-form-item>
                <el-form-item label=""><el-button  @click="handleEdit('','reset')" icon="Refresh">重置</el-button></el-form-item>
                <!-- <el-form-item label="" v-show="hasPermission(this.$route.name,'Create')"><el-button type="primary" icon="Plus" @click="handelAdd" >新增</el-button></el-form-item> -->
                <el-form-item label="" v-if="hasPermission(this.$route.name,'Delete') && true"><el-button @click="handleDelete" type="danger" :disabled="multiple" icon="Delete" >删除</el-button></el-form-item>
                <el-form-item label="" v-if="hasPermission(this.$route.name,'Export') && true"><el-button type="primary" @click="exportDataBackend" :disabled="loadingPage" icon="Download">导出</el-button></el-form-item>
            </el-form>
        </div>
        <div class="table">
            <el-table  :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="60" align="center"></el-table-column>
                <el-table-column type="index" width="60" align="center" label="序号">
                    <template #default="scope">
                        <span v-text="getIndex(scope.$index)"></span>
                    </template>
                </el-table-column>
                <el-table-column min-width="50" prop="status" label="处理状态" v-if="true">
                    <template #default="scope">
                        <span v-for="(item,index) in dictStore.data['state']">
                            <span  v-if="scope.row.status == item.value" >{{ item.label }}</span>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column min-width="50" prop="type" label="事件类型" v-if="true">
                    <template #default="scope">
                        <span v-for="(item,index) in dictStore.data['thing_type']">
                            <span  v-if="scope.row.type == item.value" >{{ item.label }}</span>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column min-width="100" prop="content" label="说事内容" v-if="false"></el-table-column>
                <el-table-column min-width="100" prop="img" label="说事图片" v-if="true">
                    <template #default="scope">
                        <el-image v-for="(item,index) in scope.row.img.split(',')" v-bind:key="index" preview-teleported :src="item" style="width: 30px;height:30px;margin-right: 5px;" :preview-src-list="[item]" v-if="scope.row.img"></el-image>
                    </template>
                </el-table-column>
                <el-table-column min-width="100" prop="user" label="说事人" v-if="true">
                    <template #default="scope">
                        {{scope.row.user_lyaudocode_name.name}}
{{scope.row.user_lyaudocode_name.mobile}}
                    </template>
                </el-table-column>
                <el-table-column min-width="100" prop="date" label="说事日期" v-if="false"></el-table-column>
                <el-table-column min-width="60" prop="jinji" label="紧急情况" v-if="true">
                    <template #default="scope">
                        <span v-for="(item,index) in dictStore.data['jinji']">
                            <span  v-if="scope.row.jinji == item.value" >{{ item.label }}</span>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column min-width="100" prop="transactors" label="办理人" v-if="true"></el-table-column>
                <el-table-column min-width="50" prop="handlingStatus" label="办理情况" v-if="false"></el-table-column>
                <el-table-column min-width="60" prop="handlingImg" label="办理图片" v-if="true">
                    <template #default="scope">
                        <el-image v-for="(item,index) in scope.row.handlingImg.split(',')" v-bind:key="index" preview-teleported :src="item" style="width: 30px;height:30px;margin-right: 5px;" :preview-src-list="[item]" v-if="scope.row.handlingImg"></el-image>
                    </template>
                </el-table-column>
                <el-table-column min-width="100" prop="push" label="向其它部门推送" v-if="false">
                    <template #default="scope">
                        <span>{{ scope.row.push_lyaudocode_name }}</span>
                    </template>
                </el-table-column>
                <el-table-column min-width="100" prop="satisfaction" label="群众满意度" v-if="false"></el-table-column>
                <el-table-column min-width="60" prop="good" label="满意度" v-if="true">
                    <template #default="scope">
                        <span v-for="(item,index) in dictStore.data['good']">
                            <span  v-if="scope.row.good == item.value" >{{ item.label }}</span>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column min-width="100" prop="dept" label="关联部门" v-if="false">
                    <template #default="scope">
                        <span>{{ scope.row.dept_lyaudocode_name }}</span>
                    </template>
                </el-table-column>
                <el-table-column min-width="100" prop="addName" label="地区名称" v-if="true"></el-table-column>
                <el-table-column min-width="150" prop="create_datetime" label="创建时间"></el-table-column>
                <el-table-column label="操作" fixed="right" width="180">
                    <template #header>
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <div>操作</div>
                            <div @click="setFull">
                                <el-tooltip content="全屏" placement="bottom">
                                    <el-icon ><full-screen /></el-icon>
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                    <template #default="scope">
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'detail')" v-show="hasPermission(this.$route.name,'Retrieve')">详情</span>
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'edit')" v-show="hasPermission(this.$route.name,'Update')">编辑</span>
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'delete')" v-show="hasPermission(this.$route.name,'Delete')">删除</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <ly-dialog v-model="dialogVisible" :title="dialogTitle" :width="dialogWidth" :top="dialogTop" :fullscreen="fullscreen" :before-close="handleDialogClose">
            <el-form :model="formData" :rules="rules" ref="rulesForm" :disabled="formDisabled" :label-position="formLabelPosition" :label-width="formLabelWidth">
                <el-row :gutter="20">
                    <el-col :span="24?24:24">
                        <el-form-item label="处理状态" prop="status" v-if="true">
                            <el-radio-group v-model="formData.status" :disabled="isFormItemEditDisabled()">
                                <el-radio :label="convertStrNum(formData.status,rditem.value)" v-for="(rditem,rdindex) in dictStore.data['state']">{{ rditem.label }}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24?24:24">
                        <el-form-item label="事件类型" prop="type" v-if="true">
                            <el-select v-model="formData.type" :disabled="isFormItemEditDisabled(true)" clearable filterable placeholder="请选择事件类型" style="width: 100%;">
                                <el-option
                                    v-for="item in dictStore.data['thing_type']"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="convertStrNum(formData.type,item.value)" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24?24:24">
                        <el-form-item label="说事内容" prop="content" v-if="true">
                            <el-input v-model="formData.content" :disabled="isFormItemEditDisabled(true)" :rows="3" type="textarea" style="width: 100%;" placeholder="请输入说事内容" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24?24:24">
                        <el-form-item label="说事图片" prop="img" v-if="true">
                            <ly-public-pictrue-multiple-upload v-model="formData.img" :disabled="isFormItemEditDisabled(true)"
                                :multiple="true" :draggable="true" :limit="10" tip="" :show-file-list="true"
                                :max-size="50">
                            </ly-public-pictrue-multiple-upload>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24?24:24">
                        <el-form-item label="说事人" prop="user" v-if="false">
                            <el-select v-model="formData.user" :disabled="isFormItemEditDisabled(false)" clearable filterable placeholder="请选择说事人" style="width: 100%;">
                                <el-option
                                    v-for="item in userLyAudoCodeAPIList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24?24:24">
                        <el-form-item label="说事日期" prop="date" v-if="false">
                            <el-date-picker type="date" v-model="formData.date" :disabled="isFormItemEditDisabled()" clearable format="YYYY-MM-DD" value-format="YYYY-MM-DD" placeholder="请选择说事日期" style="width: 100%;">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24?24:24">
                        <el-form-item label="紧急情况" prop="jinji" v-if="true">
                            <el-select v-model="formData.jinji" :disabled="isFormItemEditDisabled(true)" clearable filterable placeholder="请选择紧急情况" style="width: 100%;">
                                <el-option
                                    v-for="item in dictStore.data['jinji']"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="convertStrNum(formData.jinji,item.value)" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24?24:24">
                        <el-form-item label="办理人" prop="transactors" v-if="true">
                            <el-input v-model="formData.transactors" :disabled="isFormItemEditDisabled()" style="width: 100%;" placeholder="请输入办理人"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24?24:24">
                        <el-form-item label="办理情况" prop="handlingStatus" v-if="true">
                            <el-input v-model="formData.handlingStatus" :disabled="isFormItemEditDisabled()" :rows="3" type="textarea" style="width: 100%;" placeholder="请输入办理情况" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24?24:24">
                        <el-form-item label="办理图片" prop="handlingImg" v-if="true">
                            <ly-public-pictrue-multiple-upload v-model="formData.handlingImg" :disabled="isFormItemEditDisabled(false)"
                                :multiple="true" :draggable="true" :limit="10" tip="" :show-file-list="true"
                                :max-size="50">
                            </ly-public-pictrue-multiple-upload>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24?24:24">
                        <el-form-item label="向其它部门推送" prop="push" v-if="true">
                            <el-select v-model="formData.push" :disabled="isFormItemEditDisabled(false)" clearable filterable placeholder="请选择向其它部门推送" style="width: 100%;">
                                <el-option
                                    v-for="item in pushLyAudoCodeAPIList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id" />
                            </el-select>
                            <el-link type="danger" disabled>注意：向五办四中心一队推送民情时请确认当前民情未处理，已处理的民情不会推送。</el-link>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24?24:24">
                        <el-form-item label="群众满意度" prop="satisfaction" v-if="false">
                            <el-input v-model="formData.satisfaction" :disabled="isFormItemEditDisabled(false)" style="width: 100%;" placeholder="请输入群众满意度"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24?24:24">
                        <el-form-item label="满意度" prop="good" v-if="true">
                            <el-select v-model="formData.good" :disabled="isFormItemEditDisabled(true)" clearable filterable placeholder="请选择满意度" style="width: 100%;">
                                <el-option
                                    v-for="item in dictStore.data['good']"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="convertStrNum(formData.good,item.value)" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24?24:24">
                        <el-form-item label="关联部门" prop="dept" v-if="false">
                            <el-select v-model="formData.dept" :disabled="isFormItemEditDisabled()" clearable filterable placeholder="请选择关联部门" style="width: 100%;">
                                <el-option
                                    v-for="item in deptLyAudoCodeAPIList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24?24:24">
                        <el-form-item label="地区名称" prop="addName" v-if="true">
                            <el-input v-model="formData.addName" :disabled="isFormItemEditDisabled(true)" style="width: 100%;" placeholder="请输入地区名称"/>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template v-slot:footer>
                <el-button @click="handleDialogClose">取消</el-button>
                <el-button @click="handleDialogSubmit" type="primary" :loading="dialogLoadingSave" v-if="dialogTitle!='详情'">确定</el-button>
            </template>
        </ly-dialog>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    </div>
</template>

<script>
    // 【自定义】
    import {systemUserUserInfo,apiCustomDeptTree,apiCustomSendSMS} from '@/api/api'

    import {ajaxGet,ajaxPost,ajaxDelete,ajaxPut,ajaxPatch,ajaxDownloadExcel} from '@/api/request'
    // 列表
    const lyAutoCodeList = params => ajaxGet({url: `autocode/ThingManage/`,params})
    // 新增
    const lyAutoCodeAdd = params => ajaxPost({url: `autocode/ThingManage/`,params})
    // 编辑
    const lyAutoCodeEdit = params => ajaxPut({url: `autocode/ThingManage/`,params})
    // 删除
    const lyAutoCodeDelete = params => ajaxDelete({url: `autocode/ThingManage/`,params})
    // 导出
    const lyAutoCodeExport = params => ajaxDownloadExcel({url: `autocode/ThingManage/export/`,params})
    const userLyAudoCodeAPI = params => ajaxGet({url: `users/users/`,params})
    const pushLyAudoCodeAPI = params => ajaxGet({url: `custom/user_admin_wbszxyd/`,params})
    const deptLyAudoCodeAPI = params => ajaxGet({url: `system/dept/`,params})
    
    import {useDictStore} from "@/store/dict";
    
    import Pagination from "@/components/Pagination";
    import {dateFormats,getTableHeight,deepClone} from "@/utils/util";
    import LyDialog from "@/components/dialog/dialog";
    export default {
        name: "lyAutoCodeThingManage",
        components: {LyDialog, Pagination},
        setup(){
            const dictStore = useDictStore()
            return { dictStore }
        },
        data(){
            return{
                isFull:false,
                tableHeight:500,
                loadingPage:false,
                // 选项框选中数组
                ids: [],
                // 选项框非单个禁用
                single: true,
                // 非多个禁用
                multiple: true,
                formInline:{
                    page: 1,
                    limit: 10,
                },
                pageparm: {
                    page: 1,
                    limit: 10,
                    total: 0
                },
                timers:[],
                tableData:[],
                //弹窗
                dialogVisible:false,
                dialogTitle:"",
                dialogLoadingSave:false,
                fullscreen:false,
                dialogWidth:"50%",
                dialogTop:"30px",
                formDisabled:false,
                formLabelPosition:"right",
                formLabelWidth:"auto",
                formData:{"status": 0, "type": 3, "content": "", "img": "", "jinji": 1, "transactors": "", "handlingStatus": "", "handlingImg": null, "push": null, "good": 0, "addName": ""},
                rules:{
                    status: [{ required: true, message: "处理状态不能为空",trigger: 'blur'}],
                    type: [{ required: true, message: "事件类型不能为空",trigger: 'change'}],
                    content: [{ required: true, message: "说事内容不能为空",trigger: 'blur'}],
                    jinji: [{ required: true, message: "紧急情况不能为空",trigger: 'change'}],
                },
                userLyAudoCodeAPIList:[],
                pushLyAudoCodeAPIList:[],
                deptLyAudoCodeAPIList:[],
                // 【自定义数据】
                userInfo:{},
                deptTree:[],
                userTown:{id:'',name:''},
                choseWBSZXYDData:{id:'',name:''}
            }
        },
        methods:{
            // 表格序列号
            getIndex($index) {
                // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
                return (this.pageparm.page-1)*this.pageparm.limit + $index +1
            },
            setFull(){
                this.isFull=!this.isFull
                window.dispatchEvent(new Event('resize'))
            },
            //多选项框被选中数据
            handleSelectionChange(selection) {
                this.ids = selection.map(item => item.id);
                this.single = selection.length !== 1;
                this.multiple = !selection.length;
            },
            /** 批量删除按钮操作 */
            handleDelete(row) {
                const ids = this.ids;
                let vm = this
                vm.$confirm('是否确认删除选中的数据项?', "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(function() {
                    return lyAutoCodeDelete({id:ids}).then(res=>{
                        if(res.code == 2000) {
                            vm.$message.success(res.msg)
                            vm.getData()
                        } else {
                            vm.$message.warning(res.msg)
                        }
                    })
                })
            },
            convertStrNum(model,value){
                if(model === "" || model === null || model === undefined){
                    return value
                }
                else if(Object.prototype.toString.call(model)=== "[object String]"){
                    return value.toString()
                }
                else if(Object.prototype.toString.call(model) === "[object Number]"){
                    return value*1
                }else{
                    return value
                }
            },
            //判断单个组件编辑时是否禁用disabled参数为bool值
            isFormItemEditDisabled(disabled = false){
                if(disabled == "" || disabled == null || disabled == undefined){
                    return false
                }else if((disabled == true || disabled == 'True') && this.dialogTitle==='编辑'){
                    return true
                }
                return false
            },
            handelAdd() {
                this.dialogTitle = "添加"
                this.dialogVisible = true
                this.userLyAudoCodeAPIFunction()
                this.pushLyAudoCodeAPIFunction()
                this.deptLyAudoCodeAPIFunction()
            },
            handleDialogClose(){
                this.dialogTitle =""
                this.dialogVisible = false
                this.dialogLoadingSave = false
                this.formDisabled = false
                this.formData = {"status": 0, "type": 3, "content": "", "img": "", "jinji": 1, "transactors": "", "handlingStatus": "", "handlingImg": null, "push": null, "good": 0, "addName": ""}
            },
            handleDialogSubmit(){
                this.$refs['rulesForm'].validate((obj)=>{
                    if(obj) {
                        this.dialogLoadingSave=true
                        let param = {
                            ...this.formData
                        }
                        if(this.dialogTitle=="编辑"){
                            lyAutoCodeEdit(param).then(res=>{
                                this.dialogLoadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleDialogClose()
                                    this.getData()
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }else{
                            lyAutoCodeAdd(param).then(res=>{
                                this.dialogLoadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleDialogClose()
                                    this.getData()
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }

                    }
                })
                this.dialogLoadingSave=false
                // 【自定义】
                this.ifPush()
            },

            // 【自定义】判断是否选则了向其它部门推送
            ifPush(){
                if(this.formData.push && this.formData.status==false){
                    // 发送短信
                    this.openSMSBox()
                    console.log('选择了推送民情！！开始推送民情：', this.formData.push );
                }else{
                    console.log('不推送民情：', this.formData.push );
                }
            },
            // 【自定义】确认短信提醒弹出框
            openSMSBox() {
                // 提取选择的当前部门信息
                this.choseWBSZXYD()
                this.$confirm(`注意，该条民情未处理，并且您选择了向其它部门推送。该操作将会向【${this.choseWBSZXYDData.name}，${this.choseWBSZXYDData.mobile}】管理员发送短信提醒，是否继续?`, '短信提醒对方', {
                confirmButtonText: '确认发送',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    // 发送短信提醒
                    this.CustomSendSMS(this.choseWBSZXYDData)
                }).catch(() => {
        
                });
            },
            // 【自定义】发送短信
            CustomSendSMS(info){
                apiCustomSendSMS({"mobile":info.mobile, "identity":"0", "sms":`${this.userInfo.name} 电话：${this.userInfo.mobile}`}).then(res=>{
                    if(res.code == 2000) {
                        this.$message({
                            type: 'success',
                            message: '短信提醒成功!'
                        });
                    }else{
                        this.$message({
                            type: 'error',
                            message: '短信提醒失败，请重新提交!'
                        });
                    }
                })
            },
            // 【自定义】获取当前用户信息
            getCurrentUserInfo () {
                systemUserUserInfo().then(res=>{
                    if(res.code == 2000) {
                        this.userInfo=res.data.data
                        console.log('用户数据：', this.userInfo);
                        // 获取五办四中心一队当前部门民情数据
                        this.getData()
                        // 获取部门数据
                        let dept = localStorage.getItem("dept");
                        if(dept){
                            console.log('本地有部门数据！', JSON.parse(dept));
                            this.deptTree = JSON.parse(dept)
                            this.choseUserTown()
                            this.getCustomDeptTree()
                        }else{
                            this.getCustomDeptTree()
                        }
                    }
                })
            },
            // 【自定义】获取部门数据并计算当前用户的乡镇ID
            getCustomDeptTree () {
                apiCustomDeptTree().then(res=>{
                    if(res.code == 2000) {
                        this.deptTree=res.data.data
                        console.log('部门数据：', this.deptTree);
                        // 将部门数据存储到本地存储
                        localStorage.setItem("dept", JSON.stringify(this.deptTree));
                        this.choseUserTown()
                    }
                })
            },
            // 删选出当前用户所在乡镇
            choseUserTown(){
                // 判断当前账号部门是否为乡镇，如果不是乡镇就计算出乡镇id
                this.deptTree.forEach((item1,key)=>{
                    // console.log('当前乡镇：', item1.name, item1.id);
                    if(this.userInfo.dept_id==item1.id){
                        this.userTown.id = item1.id
                        this.userTown.name = item1.name
                    }
                    item1.children.forEach((item2,key)=>{
                        // console.log('当前村：', item2.name, item2.id);
                        if(this.userInfo.dept_id==item2.id){
                            this.userTown.id = item1.id
                            this.userTown.name = item1.name
                        }
                    })
                })
                console.log('所属乡镇为：', this.userTown.name, this.userTown.id);
                // 删选出本乡镇五办四中心一队
                this.townWBSZXYD()
            },
            // 【自定义】如果有乡镇ID就删选出该乡镇的五办四中心一队
            townWBSZXYD(){
                if(this.userTown.id && this.pushLyAudoCodeAPIList[0]){
                    // 计算出当前乡镇，并且获取到五办四中心一队时
                    let tounWBSZXYD=[]
                    this.pushLyAudoCodeAPIList.forEach((item, index)=>{
                        if(item.dept_id==this.userTown.id){
                            tounWBSZXYD.push(item)
                        }
                    })
                    this.pushLyAudoCodeAPIList = tounWBSZXYD
                    console.log('删选出该乡镇的五办四中心一队',this.pushLyAudoCodeAPIList);
                }
            },
            // 【自定义】根据选择的formData.push的id，提取该部门的电话和name
            choseWBSZXYD(){
                this.pushLyAudoCodeAPIList.forEach((item,index)=>{
                    if(this.formData.push==item.id){
                        console.log('当前选择的部门信息：', item);
                        this.choseWBSZXYDData = item
                    }
                })
            },

            handleEdit(row,flag) {
                if(flag=='edit') {
                    this.dialogTitle = "编辑"
                    this.dialogVisible = true
                    this.formData = deepClone(row)
                    this.userLyAudoCodeAPIFunction()
                    this.pushLyAudoCodeAPIFunction()
                    this.deptLyAudoCodeAPIFunction()
                }
                else if(flag=='delete') {
                    let vm = this
                    vm.$confirm('您确定要删除选中的数据吗？',{
                        closeOnClickModal:false
                    }).then(res=>{
                        lyAutoCodeDelete({id:row.id}).then(res=>{
                            if(res.code == 2000) {
                                vm.$message.success(res.msg)
                                vm.search()
                            } else {
                                vm.$message.warning(res.msg)
                            }
                        })
                    }).catch(()=>{

                    })
                }else if(flag=='detail'){
                    this.dialogTitle = "详情"
                    this.dialogVisible = true
                    this.formDisabled = true
                    this.formData = deepClone(row)
                    this.userLyAudoCodeAPIFunction()
                    this.pushLyAudoCodeAPIFunction()
                    this.deptLyAudoCodeAPIFunction()
                }else if(flag=="reset"){
                    this.formInline = {
                        page:1,
                        limit: 10
                    }
                    this.pageparm={
                        page: 1,
                        limit: 10,
                        total: 0
                    }
                    this.timers = []
                    this.getData()
                }
            },
            /**
             * 从URL里下载文件
            */
            // 下载文件
            downloadFileURL(url) {
                var a =document.createElement("a")
                var event = new MouseEvent('click')
                a.href = url
                a.target = "_blank"
                a.dispatchEvent(event)
            },
            //excel文件流下载
            downloadExcelBlob(res) {
                let fileName = new Date().getTime() +".xlsx"
                let dispositionStr = res.headers["content-disposition"];
                if (dispositionStr == null || dispositionStr === "") {

                }else{
                    // 获取文件名
                    let dispositionArr = dispositionStr.split(";");
                    fileName = decodeURIComponent(dispositionArr[1]);
                    fileName = fileName.split("=")[1];
                }
                const blob = new Blob([res.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                let href = window.URL.createObjectURL(blob); //下载链接
                let link = document.createElement("a")
                link.href = href
                link.download = fileName //下载后文件名
                document.body.appendChild(link);
                link.click(); //点击下载
                document.body.removeChild(link); //下载完成移除元素
                window.URL.revokeObjectURL(href);  //释放blob对象
                this.$message.success('导出成功')
            },
            //导出
            exportDataBackend() {
                let params = {}
                if(this.ids.length>0){
                    params = {
                        ids:this.ids,
                    }
                }
                this.loadingPage = true
                lyAutoCodeExport(params).then(res => {
                    this.loadingPage = false
                    this.downloadExcelBlob(res)
                })
            },
            callFather(parm) {
                this.formInline.page = parm.page
                this.formInline.limit = parm.limit
                this.getData()
            },
            search() {
                this.formInline.page = 1
                this.formInline.limit = 10
                this.getData()
            },
            //获取列表
            async getData() {
                this.loadingPage = true
                // 【自定义】如果有用户信息就获取
                if(this.userInfo.id){
                    if(!this.formInline.push_id){
                        Object.assign(this.formInline, {push: this.userInfo.id});
                    }
                    lyAutoCodeList(this.formInline).then(res => {
                        this.loadingPage = false
                        if(res.code ==2000) {
                            this.tableData = res.data.data
                            this.pageparm.page = res.data.page;
                            this.pageparm.limit = res.data.limit;
                            this.pageparm.total = res.data.total;
                        }
                    })
                }

            },
            timeChange(val){
                if (val) {
                    this.formInline.beginAt=dateFormats(val[0],'yyyy-MM-dd hh:mm:ss');
                    this.formInline.endAt=dateFormats(val[1],'yyyy-MM-dd hh:mm:ss');
                } else {
                    this.formInline.beginAt = null
                    this.formInline.endAt = null
                }
                this.search()
            },
            // 计算搜索栏的高度
            listenResize() {
                this.$nextTick(() => {
                    this.getTheTableHeight()
                })
            },
            getTheTableHeight(){
                let tabSelectHeight = this.$refs.tableSelect?this.$refs.tableSelect.offsetHeight:0
                tabSelectHeight = this.isFull?tabSelectHeight - 110:tabSelectHeight
                this.tableHeight =  getTableHeight(tabSelectHeight)
            },
            userLyAudoCodeAPIFunction() {
                userLyAudoCodeAPI({page:1,limit:999}).then(res => {
                    if(res.code ==2000) {
                        this.userLyAudoCodeAPIList = res.data.data
                    }
                })
            },
            pushLyAudoCodeAPIFunction() {
                pushLyAudoCodeAPI({page:1,limit:999}).then(res => {
                    if(res.code ==2000) {
                        this.pushLyAudoCodeAPIList = res.data.data
                        // 【自定义】删选出本乡镇五办四中心一队
                        console.log('五办四中心一队：', this.pushLyAudoCodeAPIList);
                        this.townWBSZXYD()
                    }
                })
            },
            deptLyAudoCodeAPIFunction() {
                deptLyAudoCodeAPI({page:1,limit:999}).then(res => {
                    if(res.code ==2000) {
                        this.deptLyAudoCodeAPIList = res.data.data
                    }
                })
            },

        },
        created() {
            // this.getData()
        },
        mounted() {
            // 监听页面宽度变化搜索框的高度
            window.addEventListener('resize', this.listenResize);
            this.$nextTick(() => {
                this.getTheTableHeight()
            })
            // 【自定义】获取用户信息
            this.getCurrentUserInfo()
        },

        unmounted() {
            // 页面销毁，去掉监听事件
            window.removeEventListener("resize", this.listenResize);
        },

    }
</script>

<style lang="scss" scoped>

</style>
