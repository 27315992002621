<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="dialogTitle" width="640px" :before-close="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right" label-width="auto">
                <el-form-item label="字典名称：" prop="label">
                    <el-input  v-model.trim="formData.label"></el-input>
                </el-form-item>
                <el-form-item label="字典编号：" prop="value">
                    <el-input  v-model.trim="formData.value"></el-input>
                </el-form-item>
                <el-form-item label="排序：" prop="sort">
                    <el-input-number v-model="formData.sort"  :min="0" :max="9999"></el-input-number>
                </el-form-item>
                <el-form-item label="备注：" prop="remark">
                    <el-input type="textarea" :rows="2" v-model="formData.remark"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">关闭</el-button>
                <el-button type="primary" @click="submitData"  :loading="loadingSave">保存</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
    import {deepClone} from "@/utils/util"
    import {systemDictionaryAdd,systemDictionaryEdit} from '@/api/api'
    import LyDialog from "@/components/dialog/dialog";
    export default {
        components: {LyDialog},
        emits: ['refreshData'],
        name: "sysDicModule",
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                dialogTitle:'',
                formData:{
                    label: '',
                    value:'',
                    sort:'',
                    remark:'',
                },
                rules:{
                    label: [
                        {required: true, message: '请输入名称',trigger: 'blur'}
                    ],
                    value: [
                        {required: true, message: '请输入key值',trigger: 'blur'}
                    ],
                },

                buttonList:[],
            }
        },
        methods:{
            handleClose() {
                this.formData = {
                    label: '',
                    value:'',
                    sort:'',
                    remark:'',
                }
                this.dialogVisible=false
                this.$emit('refreshData')
            },
            addModuleFn(item,flag) {
                this.dialogVisible=true
                this.dialogTitle=flag
                if(item){
                    this.formData = deepClone(item)
                }
            },
            submitData() {
                // console.log(this.formData,'this.formData------')
                let param = {
                    ...this.formData
                }
                this.$refs['rulesForm'].validate(obj=>{
                    if(obj) {
                        this.loadingSave=true
                        if(this.dialogTitle=="新增"){
                            systemDictionaryAdd(param).then(res=>{
                                this.loadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleClose()
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }else{
                            systemDictionaryEdit(param).then(res=>{
                                this.loadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleClose()
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }

                    }
                })
            }
        }
    }
</script>
