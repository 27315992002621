<template>
    <form-item-wrapper-ly :field="field" :rules="rules" :design-state="designState"
             :parent-widget="parentWidget" :parent-list="parentList" :index-of-parent-list="indexOfParentList">
        <el-radio-group ref="lyfieldEditor" v-model="fieldModel" :class="{'radio-group-block': field.options.displayStyle === 'block'}" :disabled="field.options.disabled" :size="widgetSize" @change="handleChangeEvent">
            <template v-if="!!field.options.buttonStyle">
                <el-radio-button v-for="(item, index) in field.options.optionItems" :key="index" :label="convertStrNum(fieldModel,item.value)"
                :disabled="item.disabled" :border="field.options.border"
                :style="{display: field.options.displayStyle}">{{item.label}}</el-radio-button>
            </template>
            <template v-else>
                <el-radio v-for="(item, index) in field.options.optionItems" :key="index" :label="lyconvertStrNum(fieldModel,item.value)"
                :disabled="item.disabled" :border="field.options.border"
                :style="{display: field.options.displayStyle}">{{item.label}}</el-radio>
            </template>
        </el-radio-group>
    </form-item-wrapper-ly>
</template>

<script setup>
    import {ref, onMounted, reactive, computed, watch, inject} from 'vue'
    import { useBuilderStore } from "@/store/lyFormBuilder";
    import useCommon from "@/components/lyform-builder/lyform-design/useCommon";

    const props = defineProps({
        field: Object,
        parentWidget: Object,
        parentList: Array,
        indexOfParentList: Number,
        designState: {
            type: Boolean,
            default: false
        },
    })
    const builderStore = useBuilderStore()
    const fieldModel = ref(null)
    let rules = ref([])
    const widgetSize = computed(() => {
        return props.field.options.size || 'default'
    })
    let {createFieldRules,getPropName,handleFieldValidation,lyconvertStrNum} = useCommon()
    const globalFormModel = inject("globalFormModel",null)
    function updateFormModel(value) {
        if (!!props.designState) {
            return
        }
        let keyName = getPropName(props)
        globalFormModel.value[keyName] = value
    }
    function handleChangeEvent(value) {
        updateFormModel(value)
        handleFieldValidation(getPropName(props))
        if (!!props.field.options.onChange) {
            let customFn = new Function('value', props.field.options.onChange)
            customFn(value)
        }
    }
    function initFieldModel() {
        if (!props.field.formItemFlag) {
            return
        }
        if(!(!!globalFormModel)){
            fieldModel.value = props.field.options.defaultValue
        }else if(globalFormModel.value[props.field.options.name]=== undefined && props.field.options.defaultValue !== undefined){
            fieldModel.value = props.field.options.defaultValue
        }else if(globalFormModel.value[props.field.options.name] === undefined){
            globalFormModel.value[props.field.options.name] = null
        }else {
            fieldModel.value = globalFormModel.value[props.field.options.name]
        }
    }
    watch(()=>props.field.options.defaultValue,(n,o)=>{
        fieldModel.value = n
    })
    onMounted(()=>{
        initFieldModel()
        createFieldRules(props.field,rules.value)
    })

</script>

<style lang="scss" scoped>
    .radio-group-block {
        display: block !important;
    }
</style>