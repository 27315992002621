<template>
    <div>
        <el-form size="small" label-position="left" label-width="100px" class="lywidgetsetting" @submit.prevent>
            <el-collapse v-model="lyCollapseActiveList" class="lyformsetting-collapse">
                <el-collapse-item name="lyws01" title="组件属性">
                    <el-form-item label="字段标识">
                        <el-input  v-model="optionModel.name" @change="handleChange"></el-input>
                    </el-form-item>
                    <el-form-item label="隐藏">
                        <el-switch v-model="optionModel.hidden" @change="handleChange"></el-switch>
                    </el-form-item>
                    <el-form-item label="主题">
                        <el-select v-model="optionModel.type" @change="handleChange">
                            <el-option label="info" value="info"></el-option>
                            <el-option label="success" value="success"></el-option>
                            <el-option label="warning" value="warning"></el-option>
                            <el-option label="error" value="error"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="文字居中">
                        <el-switch v-model="optionModel.center" @change="handleChange"></el-switch>
                    </el-form-item>
                    <el-form-item label="是否可关闭">
                        <el-switch v-model="optionModel.closable" @change="handleChange"></el-switch>
                    </el-form-item>
                    <el-form-item label="关闭按钮文字">
                        <el-input  v-model="optionModel.closeText" @change="handleChange"></el-input>
                    </el-form-item>
                    <el-form-item label="显示图标">
                        <el-switch v-model="optionModel.showIcon" @change="handleChange"></el-switch>
                    </el-form-item>
                    <el-form-item label="标题">
                        <el-input  v-model="optionModel.title" @change="handleChange"></el-input>
                    </el-form-item>
                    <el-form-item label="辅助性文字">
                        <el-input  v-model="optionModel.description" @change="handleChange"></el-input>
                    </el-form-item>
                    <el-form-item label="显示效果">
                        <el-radio-group v-model="optionModel.effect" class="radio-group-custom" @change="handleChange">
                            <el-radio-button label="light">light</el-radio-button>
                            <el-radio-button label="dark">dark</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <customClassLy :selected-widget="selectedWidget" :option-model="optionModel"></customClassLy>
                </el-collapse-item>
            </el-collapse>
        </el-form>
    </div>
</template>

<script setup>
    import {ref, onMounted, reactive, computed, watch} from 'vue'
    import { useBuilderStore } from "@/store/lyFormBuilder";
    import validationCommonLy from "@/components/lyform-builder/lysetting-pannel/lywidget-common/validation-common-ly"
    import customClassLy from "@/components/lyform-builder/lysetting-pannel/lywidget-common/custom-class-ly"
    const props = defineProps({
        selectedWidget: Object,
        optionModel: Object,
    })
    const builderStore = useBuilderStore()
    function handleChange(newValue) {
         builderStore.saveCurrentHistoryStep()
    }
    function showEventCollapse(){
        if (builderStore.builderConfig['showEventCollapse'] === undefined) {
          return true
        }
        return !!builderStore.builderConfig['showEventCollapse']
    }
    let lyCollapseActiveList =  ref(['lyws01','lyws02','lyws03'])

</script>

<style lang="scss" scoped>
    .lywidgetsetting{
        ::v-deep(.el-form-item__label) {
          font-size: 13px;
          overflow: hidden;
          white-space: nowrap;
        }
        .lyformsetting-collapse{
            ::v-deep(.el-collapse-item__header) {
                font-weight: bold;
            }
        }
    }

</style>