<template>
    <el-form-item label="自定义CSS样式">
        <el-select v-model="optionModel.customClass" multiple filterable allow-create default-first-option @change="handleChange">
          <el-option v-for="(item, idx) in cssClassList" :key="idx" :label="item" :value="item"></el-option>
        </el-select>
    </el-form-item>
</template>

<script setup>
    import {ref, reactive,onMounted} from 'vue'
    import { useBuilderStore } from "@/store/lyFormBuilder";
    import {deepClone} from '@/utils/util'
    const props = defineProps({
        selectedWidget: Object,
        optionModel: Object,
    })
    const builderStore = useBuilderStore()
    let cssClassList = ref([])
    function handleChange(newValue) {
         builderStore.saveCurrentHistoryStep()
    }
    onMounted(()=>{
        cssClassList.value = deepClone(builderStore.getCssClassList())
    })
</script>

<style lang="scss" scoped>

</style>