<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="60%" :center="true" :before-close="handleClose" top="20px">
            <el-tabs v-model="activeName">
                <el-tab-pane :label="item.file" :name="item.file" v-for="(item,index) in formData" :key="index">
                    <el-link :underline="false" icon="DocumentCopy"  @click="copyCode(item.code)" style="position:absolute;top: 10px;right: 2vh;z-index: 3333;" type="primary">复制</el-link>
                    <lyCodeEditor v-model="item.code" mode="javascript"  height="600" :read-only="true" v-if="activeName == item.file"></lyCodeEditor>
<!--                    <div style="margin-top: 20px;display: flex;justify-content: center">-->
<!--                        <el-button type="primary" @click="copyCode(item.code)">复制</el-button>-->
<!--                    </div>-->
                </el-tab-pane>
            </el-tabs>
        </ly-dialog>
    </div>
</template>

<script>
    import lyCodeEditor from '@/components/lyform-builder/code-editor'
    import LyDialog from "@/components/dialog/dialog";
    import useClipboard from "vue-clipboard3";
    import {ElMessage} from "element-plus";
    const { toClipboard } = useClipboard();
    export default {
        name: "addModulePreviewCode",
        components: {LyDialog,lyCodeEditor},
        emits: ['refreshData'],
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                loadingTitle:'',
                formData:[],
                activeName:"",
            }
        },
        methods:{
            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
                this.formData = []
                this.activeName =""
            },
            copyCode(code) {
                toClipboard(code).then(()=>{
                  this.$message.success("复制成功")
                }).catch(()=>{
                  this.$message.warning("复制失败")
                })
            },
            addModuleFn(item,flag) {
                this.loadingTitle=flag
                this.dialogVisible=true
                if(item){
                    this.formData = item
                    this.activeName = item[0].file
                }
            },
        }
    }
</script>

<style scoped>
    .ly-title{
        border-bottom: 1px solid #eee;
        margin-bottom: 20px;
        font-size: 17px;
        padding-bottom: 15px;
        color: #3c4a54;
        font-weight: bold;
    }
</style>

