export default {
	login: {
		loginInTitle: 'User Login',
		loginAccount: 'Please input Account',
		loginPWD: 'Please input Password',
		code: 'Captcha',
		rememberMe: 'Remember me',
		AccountError: 'Please input a Right Account',
		PWError: 'Please input a password',
		codeError: 'Please input a Captcha',
		login: 'Login',
		admin: 'Administrator',
		user: 'User',
		loginOther: 'Sign in with',
		accountLogin: 'Account sign in',
		wechatLoginTitle: 'QR code sign in',
		wechatLoginMsg: 'Please use wechat to scan and login(测试无实际登录)',
		wechatLoginSubMsg: 'Auto scan after 3 seconds of simulation',
		wechatLoginResult: 'Scanned',
		wechatLoginSubResult: 'Please click authorize login in the device',
		describe:'Vue3 + Django4.x Low code development platform',
	}
}