<template>
    <form-item-wrapper-ly :field="field" :rules="rules" :design-state="designState"
                     :parent-widget="parentWidget" :parent-list="parentList" :index-of-parent-list="indexOfParentList">
        <pictureSingleUpload ref="lyfieldEditor" v-model="fieldModel"
                               :disabled="field.options.disabled" :round="field.options.round" :cropper="field.options.cropper"
                               :title="field.options.uploadTitle" :show-file-list="field.options.showFileList"
                               :width="field.options.uploadWidth" :height="field.options.uploadHight"
                               :icon="field.options.uploadIcon" :compress="field.options.compress" :aspect-ratio="field.options.aspectRatio"
                               :max-size="field.options.fileMaxSize" @onSuccess="handleonSuccessEvent"></pictureSingleUpload>
    </form-item-wrapper-ly>
</template>

<script setup>
    import {ref, onMounted, reactive, computed, watch, inject} from 'vue'
    import { useBuilderStore } from "@/store/lyFormBuilder";
    import useCommon from "@/components/lyform-builder/lyform-design/useCommon";
    import pictureSingleUpload from '@/components/upload/single-picture'

    const props = defineProps({
        field: Object,
        parentWidget: Object,
        parentList: Array,
        indexOfParentList: Number,
        designState: {
            type: Boolean,
            default: false
        },
    })
    const builderStore = useBuilderStore()
    const fieldModel = ref(null)
    let rules = ref([])
    const widgetSize = computed(() => {
        return props.field.options.size || 'default'
    })

    let {createFieldRules,getPropName,handleFieldValidation} = useCommon()
    const globalFormModel = inject("globalFormModel",null)
    function updateFormModel(value) {
        if (!!props.designState) {
            return
        }
        let keyName = getPropName(props)
        globalFormModel.value[keyName] = value
    }
    function handleonSuccessEvent(value) {
        updateFormModel(value)
        handleFieldValidation(getPropName(props))
        if (!!props.field.options.onSuccess) {
            let customFn = new Function('value', props.field.options.onSuccess)
            customFn(value)
        }
    }
    function initFieldModel() {
        if (!props.field.formItemFlag) {
            return
        }
        if(!(!!globalFormModel)){
            fieldModel.value = props.field.options.defaultValue
        }else if(globalFormModel.value[props.field.options.name]=== undefined && props.field.options.defaultValue !== undefined){
            fieldModel.value = props.field.options.defaultValue
        }else if(globalFormModel.value[props.field.options.name] === undefined){
            globalFormModel.value[props.field.options.name] = null
        }else {
            fieldModel.value = globalFormModel.value[props.field.options.name]
        }
    }
    watch(()=>props.field.options.defaultValue,(n,o)=>{
        fieldModel.value = n
    })
    onMounted(()=>{
        initFieldModel()
        createFieldRules(props.field,rules.value)
    })



</script>

<style lang="scss" scoped>

</style>