<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="680px" :before-close="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right" label-width="auto" :disabled="loadingTitle=='详情'">
                <el-form-item label="版本名称：" prop="name">
                    <el-input v-model.trim="formData.name"  ></el-input>
                </el-form-item>
                <el-form-item label="版本号：" prop="version">
                    <el-input v-model.trim="formData.version" :disabled="loadingTitle=='详情'"></el-input>
                </el-form-item>
                <el-form-item label="更新时间：" prop="update_date">
                    <el-date-picker
                        style="width: 100%"
                        v-model="formData.update_date"
                        type="datetime"
                        placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="强制更新：" prop="isforce">
                    <el-radio-group v-model="formData.isforce">
                        <el-radio :label="true">强制更新</el-radio>
                        <el-radio :label="false">非强制更新</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="类型：" prop="type">
                    <el-radio-group v-model="formData.type">
                        <el-radio label="ANDROID">安卓</el-radio>
                        <el-radio label="IOS">苹果</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="下载地址：" prop="download_url">
                    <el-input v-model.trim="formData.download_url" :disabled="loadingTitle=='详情'"></el-input>
                </el-form-item>
                <el-form-item label="更新内容：" prop="content">
                    <el-input type="textarea" v-model="formData.content" :autosize="{ minRows: 4, maxRows: 6}"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave" :disabled="loadingTitle=='详情'">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
    import {systemAppversionAdd,systemAppversionEdit} from "@/api/api";
    import LyDialog from "@/components/dialog/dialog";
    import {deepClone} from "@/utils/util"
    export default {
        name: "appVersionModule",
        emits: ['refreshData'],
        components: {LyDialog},
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                loadingTitle:'',
                formData:{
                    name:"",
                    version:"",
                    update_date:"",
                    isforce:true,
                    type:'ANDROID',
                    download_url:"",
                    content:"",
                },
                rules:{
                    name: [
                        {required: true, message: '请输入版本名称',trigger: 'blur'}
                    ],
                    version: [
                        {required: true, message: '请输入版本号',trigger: 'blur'}
                    ],
                    update_date: [
                        {required: true, message: '请选择更新时间',trigger: 'blur'}
                    ],
                    download_url: [
                        {required: true, message: '请输入下载地址',trigger: 'blur'}
                    ]
                }
            }
        },
        methods:{
            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
                this.formData = {
                    name:"",
                    version:"",
                    update_date:"",
                    isforce:true,
                    type:'ANDROID',
                    download_url:"",
                    content:"",
                }
                this.$emit('refreshData')
            },
            addModuleFn(item,flag) {
                this.loadingTitle=flag
                this.dialogVisible=true
                if(item){
                    this.formData= deepClone(item)
                }
            },
            submitData() {
                this.$refs['rulesForm'].validate(obj=>{
                    if(obj) {
                        this.loadingSave=true
                        let param = {
                            ...this.formData
                        }
                        if(this.formData.id){
                            systemAppversionEdit(param).then(res=>{
                                this.loadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleClose()
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }else{
                            systemAppversionAdd(param).then(res=>{
                                this.loadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleClose()
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }
                    }
                })
            }
        }
    }
</script>