<!--
 * @Descripttion: 水印组件
 * @version: 1.0
 * @program: django-vue-lyadmin 专业版
 * @Author: lybbn
 * @Date: 2023.05.02
-->
<template>
    <div class="lywatermark" ref="lyWaterMark">
		<slot></slot>
	</div>
</template>

<script setup>
    import {ref, onMounted, reactive} from 'vue'

	const props = defineProps({
        text: {
            type: String,
            required: true,
            default: ""
        },
        subtext: {
            type: String,
            default: ""
        },
        color: {
            type: String,
            default: "rgba(128,128,128,0.2)"
        }
    })
    let lyWaterMark = ref(null)

    function clear(){
        var waterDom = lyWaterMark.value.querySelector('.watermark')
        waterDom && waterDom.remove()
    }
    function create(){
        clear()
        //创建画板
        var canvas = document.createElement('canvas')
        canvas.width = 160
        canvas.height = 160
        canvas.style.display = 'none'
        //绘制文字
        var text = canvas.getContext('2d')
        text.rotate(-45 * Math.PI / 180)
        text.translate(-70, 25)
        text.fillStyle = props.color
        text.font = "bold 19px SimHei"
        text.textAlign = "center"
        text.fillText(props.text, canvas.width / 2, canvas.height / 2)
        text.font = "14px Microsoft YaHei"
        text.fillText(props.subtext, canvas.width / 2, canvas.height / 2 + 20)
        //创建水印容器
        var watermark = document.createElement('div')
        watermark.setAttribute('class', 'watermark')
        const styleStr = `position:absolute;top:0;left:0;right:0;bottom:0;z-index:99;pointer-events:none;background-repeat:repeat;background-image:url('${canvas.toDataURL("image/png")}');`
        watermark.setAttribute('style', styleStr);
        lyWaterMark.value.appendChild(watermark)
    }

    onMounted(()=>{
        create()
    })
	defineExpose({
		clear,
		create
	})
</script>

<style scoped>
    .lywatermark {
        position: relative;
        display: inherit;
        width: 100%;
        height: 100%;
    }
</style>