<template>
    <form-item-wrapper-ly :field="field" :rules="rules" :design-state="designState"
                     :parent-widget="parentWidget" :parent-list="parentList" :index-of-parent-list="indexOfParentList">
        <fileMultipleUpload ref="lyfieldEditor" v-model="fieldModel" :accept="accept"
                               :disabled="field.options.disabled" :multiple="field.options.multipleSelect" :drag="field.options.draggable"
                               :limit="field.options.limit" :tip="field.options.uploadTip" :show-file-list="field.options.showFileList"
                               :max-size="field.options.fileMaxSize" :icon="field.options.uploadIcon" :button-type="field.options.buttonType" @onSuccess="handleonSuccessEvent">
            <el-icon class="el-icon--upload" v-if="field.options.draggable"><upload-filled /></el-icon>
            <div class="el-upload__text" v-if="field.options.draggable">
              拖拽文件到这 或 <em>{{field.options.uploadTitle}}</em>
            </div>
        </fileMultipleUpload>
    </form-item-wrapper-ly>
</template>

<script setup>
    import {ref, onMounted, reactive, computed, watch, inject} from 'vue'
    import { useBuilderStore } from "@/store/lyFormBuilder";
    import useCommon from "@/components/lyform-builder/lyform-design/useCommon";
    import fileMultipleUpload from '@/components/upload/lyfileUpload'

    const props = defineProps({
        field: Object,
        parentWidget: Object,
        parentList: Array,
        indexOfParentList: Number,
        designState: {
            type: Boolean,
            default: false
        },
    })
    const builderStore = useBuilderStore()
    const fieldModel = ref(null)
    let rules = ref([])
    const widgetSize = computed(() => {
        return props.field.options.size || 'default'
    })
    const accept = computed(() => {
        return props.field.options.fileTypes.length>0?props.field.options.fileTypes.join(","):""
    })

    let {createFieldRules,getPropName,handleFieldValidation} = useCommon()
    const globalFormModel = inject("globalFormModel",null)
    function updateFormModel(value) {
        if (!!props.designState) {
            return
        }
        let keyName = getPropName(props)
        globalFormModel.value[keyName] = value
    }
    function handleonSuccessEvent(value) {
        updateFormModel(value)
        handleFieldValidation(getPropName(props))
        if (!!props.field.options.onSuccess) {
            let customFn = new Function('value', props.field.options.onSuccess)
            customFn(value)
        }
    }
    function initFieldModel() {
        if (!props.field.formItemFlag) {
            return
        }
        if(!(!!globalFormModel)){
            fieldModel.value = props.field.options.defaultValue
        }else if(globalFormModel.value[props.field.options.name]=== undefined && props.field.options.defaultValue !== undefined){
            fieldModel.value = props.field.options.defaultValue
        }else if(globalFormModel.value[props.field.options.name] === undefined){
            globalFormModel.value[props.field.options.name] = null
        }else {
            fieldModel.value = globalFormModel.value[props.field.options.name]
        }
    }
    watch(()=>props.field.options.defaultValue,(n,o)=>{
        fieldModel.value = n
    })
    onMounted(()=>{
        initFieldModel()
        createFieldRules(props.field,rules.value)
    })



</script>

<style lang="scss" scoped>

</style>