<template>
    <td v-if="designState" class="lytable-cell" :class="[selected ? 'selected' : '', customClass]"
        :style="{width: widget.options.cellWidth + '!important' || '', height: widget.options.cellHeight + '!important' || ''}"
        :colspan="widget.options.colspan || 1" :rowspan="widget.options.rowspan || 1"
        @click.stop="selectWidget(widget,designState)">
        <draggable :list="widget.widgetList" item-key="id" v-bind="{group:'lyDragGroup', ghostClass: 'lyformbuilder-ghost',animation: 200}"
                tag="transition-group" :component-data="{name: 'fade'}"
                handle=".drag-handler" @end="(evt) => onTableDragEnd(evt, widget.widgetList)"
                @add="(evt) => onTableDragAdd(evt, widget.widgetList)"
                @update="onTableDragUpdate" :move="handleContainerMove">
        <template #item="{ element: subWidget, index: swIdx }">
            <div class="form-widget-list">
                <template v-if="subWidget.category === 'container'">
                    <component :is="subWidget.type + '-widget-ly'" :widget="subWidget" :key="subWidget.id" :parent-list="widget.widgetList" :index-of-parent-list="swIdx" :parent-widget="widget" :design-state="designState"></component>
                </template>
                <template v-else>
                    <component :is="subWidget.type + '-widget-ly'" :field="subWidget" :key="subWidget.id" :parent-list="widget.widgetList" :index-of-parent-list="swIdx" :parent-widget="widget" :design-state="designState"></component>
                </template>
            </div>
        </template>
        </draggable>

        <div class="table-cell-action" v-if="selected && widget.type === 'table-cell'">
            <el-tag effect="dark"  closable @close="handleCloseActionTag()" v-show="isActionShow" size="small" type="warning">
                <span title="选中父组件" @click.stop="selectParentWidget(parentWidget)"><svg-icon icon-class="back" /></span>
                <el-dropdown trigger="click" @command="handleTableCellCommand" size="small">
                <span title="单元格操作"><svg-icon icon-class="menu" /></span>
                <template #dropdown>
                    <el-dropdown-menu size="small">
                        <el-dropdown-item command="insertLeftCol">插入左侧列</el-dropdown-item>
                        <el-dropdown-item command="insertRightCol">插入右侧列</el-dropdown-item>
                        <el-dropdown-item command="insertAboveRow">插入上方行</el-dropdown-item>
                        <el-dropdown-item command="insertBelowRow">插入下方行</el-dropdown-item>
                        <el-dropdown-item command="mergeLeftCol" :disabled="mergeLeftColDisabled" divided>合并左侧单元格</el-dropdown-item>
                        <el-dropdown-item command="mergeRightCol" :disabled="mergeRightColDisabled">合并右侧单元格</el-dropdown-item>
                        <el-dropdown-item command="mergeWholeRow" :disabled="mergeWholeRowDisabled">合并整行</el-dropdown-item>
                        <el-dropdown-item command="mergeAboveRow" :disabled="mergeAboveRowDisabled" divided>合并上方单元格</el-dropdown-item>
                        <el-dropdown-item command="mergeBelowRow" :disabled="mergeBelowRowDisabled">合并下方单元格</el-dropdown-item>
                        <el-dropdown-item command="mergeWholeCol" :disabled="mergeWholeColDisabled">合并整列</el-dropdown-item>
                        <el-dropdown-item command="undoMergeRow" :disabled="undoMergeRowDisabled" divided>撤销行合并</el-dropdown-item>
                        <el-dropdown-item command="undoMergeCol" :disabled="undoMergeColDisabled">撤销列合并</el-dropdown-item>
                        <el-dropdown-item command="deleteWholeCol" :disabled="deleteWholeColDisabled" divided>删除整列</el-dropdown-item>
                        <el-dropdown-item command="deleteWholeRow" :disabled="deleteWholeRowDisabled">删除整行</el-dropdown-item>
                    </el-dropdown-menu>
                </template>
                </el-dropdown>
            </el-tag>
        </div>
        <div class="table-cell-handler" v-if="selected && widget.type === 'table-cell'">
            <el-tag effect="dark"  closable @close="handleCloseDragTag()" v-show="isDragHandlerShow" size="small" type="warning">{{widget.displayName}}</el-tag>
        </div>
    </td>
    <td v-else class="lytable-cell2" :class="[customClass]" :colspan="widget.options.colspan || 1" :rowspan="widget.options.rowspan || 1" :style="{width: widget.options.cellWidth + ' !important' || '', height: widget.options.cellHeight + ' !important' || ''}">
        <template v-for="(subWidget, swIdx) in widget.widgetList">
            <template v-if="'container' === subWidget.category">
            <component :is="subWidget.type + '-widget-ly'" :widget="subWidget" :key="swIdx" :parent-list="widget.widgetList" :index-of-parent-list="swIdx" :parent-widget="widget" :design-state="designState"></component>
            </template>
            <template v-else>
                <component :is="subWidget.type + '-widget-ly'" :field="subWidget" :key="swIdx" :parent-list="widget.widgetList" :index-of-parent-list="swIdx" :parent-widget="widget" :design-state="designState"></component>
            </template>
        </template>
    </td>

</template>

<script setup>
    import {ref, onMounted, reactive, computed, nextTick, watch} from 'vue'
    import useCommon from '@/components/lyform-builder/lyform-design/useCommon'
    import { useBuilderStore } from "@/store/lyFormBuilder"

    const props = defineProps({
        widget: Object,
        parentWidget: Object,
        parentList: Array,

        rowIndex: Number,
        colIndex: Number,
        rowLength: Number,
        colLength: Number,
        colArray: Array,
        rowArray: Array,

        designState: {
            type: Boolean,
            default: false
        },
    })
    const builderStore = useBuilderStore()

    let isDragHandlerShow = ref(true)
    let isActionShow = ref(true)

    const selected = computed(() => {
        return props.designState&&props.widget.id === builderStore.selectedId
    })
    let {selectWidget,selectParentWidget} = useCommon()
    const customClass = computed(() => {
        return props.widget.options.customClass || ''
    })
    const mergeLeftColDisabled = computed(() => {
        return (props.colIndex <= 0) || (props.colArray[props.colIndex - 1].options.rowspan !== props.widget.options.rowspan)
    })
    const mergeRightColDisabled = computed(() => {
        let rightColIndex = props.colIndex + props.widget.options.colspan
        return (props.colIndex >= props.colLength - 1) || (rightColIndex > props.colLength -1) || (props.colArray[rightColIndex].options.rowspan !== props.widget.options.rowspan)
    })

    const mergeWholeRowDisabled = computed(() => {
        return (props.colLength <= 1) || (props.colLength === props.widget.options.colspan)
    })

    const mergeAboveRowDisabled = computed(() => {
        return (props.rowIndex <= 0) || (props.rowArray[props.rowIndex - 1].cols[props.colIndex].options.colspan !== props.widget.options.colspan)
    })

    const mergeBelowRowDisabled = computed(() => {
        let belowRowIndex = props.rowIndex + props.widget.options.rowspan
        return (props.rowIndex >= props.rowLength - 1) || (belowRowIndex > props.rowLength -1) || (props.rowArray[belowRowIndex].cols[props.colIndex].options.colspan !== props.widget.options.colspan)
    })

    const mergeWholeColDisabled = computed(() => {
        return (props.rowLength <= 1) || (props.rowLength === props.widget.options.rowspan)
    })

    const undoMergeColDisabled = computed(() => {
        return props.widget.merged || (props.widget.options.colspan <= 1)
    })

    const undoMergeRowDisabled = computed(() => {
        return props.widget.merged || (props.widget.options.rowspan <= 1)
    })

    const deleteWholeColDisabled = computed(() => {
        return (props.colLength === 1) || (props.widget.options.colspan === props.colLength)
    })

    const deleteWholeRowDisabled = computed(() => {
        return (props.rowLength === 1) || (props.widget.options.rowspan === props.rowLength)
    })


    function handleContainerMove(evt) {
        return builderStore.handleWidgetMove(evt)
    }

    function onTableDragEnd(obj, subList) {

    }

    function onTableDragAdd(evt, subList) {
        const newIndex = evt.newIndex
        if (!!subList[newIndex]) {
            builderStore.setSelected( subList[newIndex] )
        }
        builderStore.emitHistoryChange()
      }

    function onTableDragUpdate() {
        builderStore.emitHistoryChange()
    }

    function handleTableCellCommand(command) {
        if (command === 'insertLeftCol') {
            insertLeftCol()
        } else if (command === 'insertRightCol') {
            insertRightCol()
        } else if (command === 'insertAboveRow') {
            insertAboveRow()
        } else if (command === 'insertBelowRow') {
            insertBelowRow()
        } else if (command === 'mergeLeftCol') {
            mergeLeftCol()
        } else if (command === 'mergeRightCol') {
            mergeRightCol()
        } else if (command === 'mergeWholeCol') {
            mergeWholeCol()
        } else if (command === 'mergeAboveRow') {
            mergeAboveRow()
        } else if (command === 'mergeBelowRow') {
            mergeBelowRow()
        } else if (command === 'mergeWholeRow') {
            mergeWholeRow()
        } else if (command === 'undoMergeCol') {
            undoMergeCol()
        } else if (command === 'undoMergeRow') {
            undoMergeRow()
        } else if (command === 'deleteWholeCol') {
            deleteWholeCol()
        } else if (command === 'deleteWholeRow') {
            deleteWholeRow()
        }
    }

    function insertLeftCol() {
        builderStore.insertTableCol(props.parentWidget, props.colIndex, props.rowIndex, true)
    }

    function insertRightCol() {
        builderStore.insertTableCol(props.parentWidget, props.colIndex, props.rowIndex, false)
    }

    function insertAboveRow() {
        builderStore.insertTableRow(props.parentWidget, props.rowIndex, props.rowIndex, props.colIndex, true)
    }

    function insertBelowRow() {
        builderStore.insertTableRow(props.parentWidget, props.rowIndex, props.rowIndex, props.colIndex, false)
    }

    function mergeLeftCol() {
        builderStore.mergeTableCol(props.rowArray, props.colArray, props.rowIndex, props.colIndex, true, props.widget)
    }

    function mergeRightCol() {
        builderStore.mergeTableCol(props.rowArray, props.colArray, props.rowIndex, props.colIndex, false, props.widget)
    }

    function mergeWholeRow() {
        builderStore.mergeTableWholeRow(props.rowArray, props.colArray, props.rowIndex, props.colIndex)
    }

    function mergeAboveRow() {
        builderStore.mergeTableRow(props.rowArray, props.rowIndex, props.colIndex, true, props.widget)
    }

    function mergeBelowRow() {
        builderStore.mergeTableRow(props.rowArray, props.rowIndex, props.colIndex, false, props.widget)
    }

    function mergeWholeCol() {
        builderStore.mergeTableWholeCol(props.rowArray, props.colArray, props.rowIndex, props.colIndex)
    }

    function undoMergeCol() {
        builderStore.undoMergeTableCol(props.rowArray, props.rowIndex, props.colIndex, props.widget.options.colspan, props.widget.options.rowspan)
    }

    function undoMergeRow() {
        builderStore.undoMergeTableRow(props.rowArray, props.rowIndex, props.colIndex, props.widget.options.colspan, props.widget.options.rowspan)
    }

    function deleteWholeCol() {
        builderStore.deleteTableWholeCol(props.rowArray, props.colIndex)
    }

    function deleteWholeRow() {
        builderStore.deleteTableWholeRow(props.rowArray, props.rowIndex)
    }

    function handleCloseDragTag() {
        isDragHandlerShow.value = false
    }
    function handleCloseActionTag() {
        isActionShow.value = false
    }

    watch(()=>builderStore.selectedId,(n,o)=>{
        if(n === props.widget.id && props.widget.type === 'table-cell'&&props.designState){
            isDragHandlerShow.value = true
            isActionShow.value = true
        }
    })


</script>

<style lang="scss" scoped>
    .lytable-cell{
        border: 1px dashed #336699;
        display: table-cell;
        position: relative;
        .draggable-div {
            position: relative;
            height: 100%;
        }
        .form-widget-list {
            border: 1px dashed #336699;
            margin: 3px;
            min-height: 28px;
        }
        .table-cell-action{
            position: absolute;
            bottom: 0;
            right: -2px;
            background: var(--el-color-warning);
            z-index: 999;

            display: flex;
            align-items: center;
            .el-tag{
                border-radius:0;
                .el-icon{
                    font-size: 14px !important;
                }
            }
            span {
                font-size: 14px;
                color: #fff;
                margin: 0 5px;
                cursor: pointer;
            }
        }
        .table-cell-handler{
            position: absolute;
            top: -1px;
            left: -2px;
            z-index: 9;
            .el-tag{
                opacity:0.4;
                border-radius:0;
                ::v-deep(.el-tag__content){
                    display: flex;
                    align-items: center;
                }
                ::v-deep(.svg-icon-lyicon){
                    cursor: move !important;
                }
                ::v-deep(.svg-icon){
                    cursor: move !important;
                }
                &:hover {
                    opacity: 1;
                }

            }
        }
    }
    .lytable-cell.selected {
        outline: 2px solid var(--el-color-warning) !important;
    }
    .lytable-cell2{
        display: table-cell;
        height: 36px;
        border: 1px solid #e5e5e5;
    }
</style>