<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="dialogTitle" width="640px" :before-close="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right" label-width="auto">
                <el-form-item label="所属字典：" prop="parent">
                    <el-select v-model="formData.parent" placeholder="请选择" clearable filterable style="width:100%">
                        <el-option
                                v-for="item in dicList"
                                :key="item.id"
                                :label="item.label"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="项名称" prop="label">
                    <el-input v-model="formData.label" clearable></el-input>
                </el-form-item>
                <el-form-item label="数据值" prop="value">
                    <el-input v-model="formData.value" clearable></el-input>
                </el-form-item>
                <el-form-item label="是否有效" prop="status">
                    <el-switch v-model="formData.status"></el-switch>
                </el-form-item>
                <el-form-item label="排序：" prop="sort">
                    <el-input-number v-model="formData.sort"  :min="0" :max="9999"></el-input-number>
                </el-form-item>
                <el-form-item label="备注：" prop="remark">
                    <el-input type="textarea" :rows="2" v-model="formData.remark"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">关闭</el-button>
                <el-button type="primary" @click="submitData"  :loading="loadingSave">保存</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
    import {deepClone} from "@/utils/util"
    import {systemDictionary,systemDictionaryAdd,systemDictionaryEdit} from '@/api/api'
    import LyDialog from "@/components/dialog/dialog";
    export default {
        components: {LyDialog},
        emits: ['refreshData'],
        name: "sysDicListModule",
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                dialogTitle:'',
                formData:{
                    parent:'',
                    label: '',
                    value:'',
                    sort:'',
                    status:true,
                    remark:'',
                },
                rules:{
                    parent: [
                        {required: true, message: '请选择所属字典',trigger: 'blur'}
                    ],
                    label: [
                        {required: true, message: '请输入项名称',trigger: 'blur'}
                    ],
                    value: [
                        {required: true, message: '请输入数据值',trigger: 'blur'}
                    ],
                },
                dicList: [],
            }
        },
        methods:{
            handleClose() {
                this.formData = {
                    parent:'',
                    label: '',
                    value:'',
                    sort:'',
                    status:true,
                    remark:'',
                }
                this.dialogVisible=false
                this.$emit('refreshData')
            },
            //获取字典列表
			async getDic(){
                 systemDictionary({parent_isnull:true,page:1,limit:999}).then(res => {
                     this.showDicloading = false
                     if(res.code ==2000) {
                         this.dicList = res.data.data
                     }
                 })
			},
            addModuleFn(item,flag,parent) {
                this.dialogVisible=true
                this.dialogTitle=flag
                this.getDic()
                if(item){
                    this.formData = deepClone(item)
                }else{
                    this.formData.parent = parent
                }
            },
            submitData() {
                // console.log(this.formData,'this.formData------')
                let param = {
                    ...this.formData
                }
                this.$refs['rulesForm'].validate(obj=>{
                    if(obj) {
                        this.loadingSave=true
                        if(this.dialogTitle=="新增"){
                            systemDictionaryAdd(param).then(res=>{
                                this.loadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleClose()
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }else{
                            systemDictionaryEdit(param).then(res=>{
                                this.loadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleClose()
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }

                    }
                })
            }
        }
    }
</script>
