<template>
    <static-content-wrapper-ly :field="field" :rules="rules" :design-state="designState"
                     :parent-widget="parentWidget" :parent-list="parentList" :index-of-parent-list="indexOfParentList">
        <el-statistic ref="lyfieldEditor" :value="field.options.value" :decimal-separator="field.options.decimalSeparator"
                      :group-separator="field.options.groupSeparator" :precision="field.options.precision">
            <template #title>
                <div style="display: inline-flex; align-items: center">
                    {{field.options.title}}
                </div>
            </template>
            <template #prefix v-if="field.options.prefix">{{field.options.prefix}}</template>
            <template #suffix v-if="field.options.suffix">{{field.options.suffix}}</template>
        </el-statistic>
    </static-content-wrapper-ly>
</template>

<script setup>
    import {ref, onMounted, reactive, computed, watch} from 'vue'
    import { useBuilderStore } from "@/store/lyFormBuilder";

    const props = defineProps({
        field: Object,
        parentWidget: Object,
        parentList: Array,
        indexOfParentList: Number,
        designState: {
            type: Boolean,
            default: false
        },
    })
    const builderStore = useBuilderStore()
    const fieldModel = ref(null)
    let rules = ref([])
    const widgetSize = computed(() => {
        return props.field.options.size || 'default'
    })

    function initFieldModel() {
        if (!props.field.formItemFlag) {
            return
        }
        fieldModel.value = props.field.options.defaultValue
    }
    watch(()=>props.field.options.defaultValue,(n,o)=>{
        fieldModel.value = n
    })

    onMounted(()=>{
        initFieldModel()
    })



</script>

<style lang="scss" scoped>

</style>