<template>
    <form-item-wrapper-ly :field="field" :rules="rules" :design-state="designState"
                     :parent-widget="parentWidget" :parent-list="parentList" :index-of-parent-list="indexOfParentList">
        <el-switch ref="lyfieldEditor" v-model="fieldModel"
               :disabled="field.options.disabled"
               :size="widgetSize"
               :inline-prompt="field.options.inlinePrompt"
               :style="widgetStyle"
               :active-text="field.options.activeText" :inactive-text="field.options.inactiveText"
               :active-color="field.options.activeColor" :inactive-color="field.options.inactiveColor"
               :width="field.options.switchWidth"
               @change="handleChangeEvent">
        </el-switch>
    </form-item-wrapper-ly>
</template>

<script setup>
    import {ref, onMounted, reactive, computed, watch, inject} from 'vue'
    import { useBuilderStore } from "@/store/lyFormBuilder";
    import useCommon from "@/components/lyform-builder/lyform-design/useCommon";

    const props = defineProps({
        field: Object,
        parentWidget: Object,
        parentList: Array,
        indexOfParentList: Number,
        designState: {
            type: Boolean,
            default: false
        },
    })
    const builderStore = useBuilderStore()
    const fieldModel = ref(null)
    let rules = ref([])
    const inputType = computed(() => {
        if (props.field.options.type === 'number') {
          return 'text'  //当input的type设置为number时，如果输入非数字字符，则v-model拿到的值为空字符串，无法实现输入校验！故屏蔽之！！
        }
        return props.field.options.type
    })
    const widgetSize = computed(() => {
        return props.field.options.size || 'default'
    })
     const widgetStyle = computed(() => {
         if(props.field.options.activeColor && props.field.options.inactiveColor){
             return `--el-switch-on-color:${props.field.options.activeColor}; --el-switch-off-color: ${props.field.options.inactiveColor}`
         }else if(props.field.options.activeColor){
             return `--el-switch-on-color:${props.field.options.activeColor}`
         }else if(props.field.options.inactiveColor){
             return `--el-switch-off-color:${props.field.options.inactiveColor}`
         }
         return null
    })

    let {createFieldRules,getPropName,handleFieldValidation} = useCommon()
    const globalFormModel = inject("globalFormModel",null)
    function updateFormModel(value) {
        if (!!props.designState) {
            return
        }
        let keyName = getPropName(props)
        globalFormModel.value[keyName] = value
    }
    function handleChangeEvent(value) {
        updateFormModel(value)
        handleFieldValidation(getPropName(props))
        if (!!props.field.options.onChange) {
            let customFn = new Function('value', props.field.options.onChange)
            customFn(value)
        }
    }

    function initFieldModel() {
        if (!props.field.formItemFlag) {
            return
        }
        if(!(!!globalFormModel)){
            fieldModel.value = props.field.options.defaultValue
        }else if(globalFormModel.value[props.field.options.name]=== undefined && props.field.options.defaultValue !== undefined){
            fieldModel.value = props.field.options.defaultValue
        }else if(globalFormModel.value[props.field.options.name] === undefined){
            globalFormModel.value[props.field.options.name] = null
        }else {
            fieldModel.value = globalFormModel.value[props.field.options.name]
        }
    }
    watch(()=>props.field.options.defaultValue,(n,o)=>{
        fieldModel.value = n
    })

    onMounted(()=>{
        initFieldModel()
        createFieldRules(props.field,rules.value)
    })



</script>

<style lang="scss" scoped>
</style>