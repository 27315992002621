<template>
    <div>
        <el-card shadow="never">
            <el-tabs v-model="activeName" type="card" @tab-change="handleChange">
                <el-tab-pane :label="item.name" :name="item.id" v-for="(item,index) in logList" :key="index">
                    <div v-loading="loadingPage">
                        <el-button @click="handleRefresh(activeName)" icon="refresh" type="primary" style="margin-bottom: 10px;" :loading="loadingPage">刷新日志</el-button>
                        <lyCodeEditor v-model="item.content" mode="javascript" :height="tableHeight" :read-only="true" :bottom="true" ref="lyCodemirror"></lyCodeEditor>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </el-card>
    </div>

</template>
<script>
    import lyCodeEditor from '@/components/lyform-builder/code-editor'
    import {getTableHeight} from "@/utils/util";
    import {getOperationLogSystemLogs} from '@/api/api'
    export default {
        components:{
            lyCodeEditor
        },
        name:'lySystemLogs',
        data() {
            return {
                isFull:false,
                tableHeight:500,
                loadingPage:false,
                activeName:1,
                logList:[
                    {id:1,name:'error.log',content:""},
                    {id:2,name:'server.log',content:""},
                ]
            }
        },
        methods:{
            setFull(){
                this.isFull=!this.isFull
                window.dispatchEvent(new Event('resize'))
            },
            handleChange() {
                this.handleRefresh()
            },
            handleRefresh(){
                this.getData()
            },
            //获取列表
            async getData(){
                this.loadingPage = true
                getOperationLogSystemLogs({type:this.activeName}).then(res => {
                    this.loadingPage = false
                    if(res.code ==2000) {
                        this.logList[this.activeName - 1].content = res.data
                        this.$nextTick(()=>{
                            this.$refs.lyCodemirror[this.activeName-1].scrollToBottom()
                        })
                    }
                })
            },
            // 计算搜索栏的高度
            listenResize() {
				this.$nextTick(() => {
				    this.getTheTableHeight()
				})
			},
            getTheTableHeight(){
                let tabSelectHeight = 80
                tabSelectHeight = this.isFull?tabSelectHeight - 100:tabSelectHeight
                this.tableHeight =  getTableHeight(tabSelectHeight)
            }
        },
        created() {
            this.getData()
        },
        mounted() {
            // 监听页面宽度变化搜索框的高度
            window.addEventListener('resize', this.listenResize);
            this.$nextTick(() => {
                this.getTheTableHeight()
            })
        },
        unmounted() {
              // 页面销毁，去掉监听事件
			window.removeEventListener("resize", this.listenResize);
        },
    }
</script>


