import { ref, onMounted ,nextTick,defineProps,computed,getCurrentInstance} from 'vue'
import { useBuilderStore } from "@/store/lyFormBuilder";
import FormValidators from '@/components/lyform-builder/lygenerate/validators'

export default function() {

    const builderStore = useBuilderStore()
    let bus = getCurrentInstance().appContext.config.globalProperties.$Bus; // 声明$Bus

    //类型转换：适用radio、select等选择组件value值与后台存储类不一致问题导致无法默认选中
    function lyconvertStrNum(model,value){
        if(model == "" || model == null || model == undefined){
            return value
        }
        else if(Object.prototype.toString.call(model)=== "[object String]"){
            return value.toString()
        }
        else if(Object.prototype.toString.call(model) === "[object Number]"){
            return value*1
        }else{
            return value
        }
    }

    function selectWidget(widget,designState=true) {
        if(designState){
            builderStore.setSelected(widget)
        }
    }

    function selectParentWidget(parentWidget) {
        if (parentWidget) {
            builderStore.setSelected(parentWidget)
        } else {
            builderStore.clearSelected()
        }
    }

    function getPropName(props) {
        return props.field.options.name
    }

    function handleFieldValidation(fieldName) {
        bus.emit('fieldValidation',fieldName)
    }

    function createFieldRules(field,rules) {
        if (!field.formItemFlag && field.options.hidden) {
            return
        }

        rules.splice(0, rules.length)  //清空已有
        if (!!field.options.required) {
            rules.push({
                required: true,
                trigger: ['blur'],
                message: field.options.requiredHint || '字段值不可为空',
            })
        }

        if (!!field.options.validation) {
            let validName = field.options.validation
            if (!!FormValidators[validName]) {
                rules.push({
                    validator: FormValidators[validName],
                    trigger: ['blur', 'change'],
                    label: field.options.label,
                    errorMsg: field.options.validationHint
                })
            } else {
                rules.push({
                    validator: FormValidators['regExp'],
                    trigger: ['blur', 'change'],
                    regExp: validName,
                    label: field.options.label,
                    errorMsg: field.options.validationHint
                })
            }
        }

        if (!!field.options.onValidate) {
            let customFn = (rule, value, callback) => {
                let tmpFunc =  new Function('rule', 'value', 'callback', field.options.onValidate)
                return tmpFunc.call(rule, value, callback)
            }
            rules.push({
                validator: customFn,
                trigger: ['blur', 'change'],
                label: field.options.label
            })
        }
    }

    onMounted(() => {

    })

    return {
        lyconvertStrNum,
        selectWidget,
        selectParentWidget,
        createFieldRules,
        getPropName,
        handleFieldValidation
    }
}