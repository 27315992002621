import { defineStore } from 'pinia'
import { apiGetdictionaryall } from '@/api/api'
import * as apiContent from '@/api/api'

export const useDictStore = defineStore('dict', {
    state:() => {
        return {
            data: {},//数据字典 MAP(所有)
            apiNameList:[],//获取本地api文件中的接口变量
        }
    },
    getters:{

    },
    actions: {
        /**
         * 获取所有字典
         * @methods getDictAll 获取所有字典
         */
        async getDictAll(){
            apiGetdictionaryall().then(res => {
                if (res.code == 2000) {
                    // 转换数据格式并保存到pinia
                    let dataList = res.data.data
                    dataList.forEach((item) => {
                        let childrens = item.children
                        this.data[item.value] = childrens
                    })
                }
            })
        },
        /**
         * 获取本地api文件中的接口变量
         */
        async getApiNameList(){
            Object.keys(apiContent).forEach(key => {
                let name = apiContent[key].name
                this.apiNameList.push({
                    'label':name,
                    'value':params
                })
            })
        }
    },
})