<template>
    <form-item-wrapper-ly :field="field" :rules="rules" :design-state="designState"
                     :parent-widget="parentWidget" :parent-list="parentList" :index-of-parent-list="indexOfParentList">
        <div :style="{width:field.options.columnWidth+'%'}">
            <lyTEditor ref="lyfieldEditor" v-model="fieldModel" :placeholder="field.options.placeholder"
                       :disabled="field.options.disabled ||builderStore.formConfig.disabled" :height="field.options.contentHeight" @blur="handleBlurEvent" @change="handleChangeEvent"></lyTEditor>
        </div>
    </form-item-wrapper-ly>
</template>

<script setup>
    import {ref, onMounted, reactive, computed, watch, defineAsyncComponent, inject} from 'vue'
    import { useBuilderStore } from "@/store/lyFormBuilder";
    import useCommon from '@/components/lyform-builder/lyform-design/useCommon'
	const lyTEditor = defineAsyncComponent(() => import('@/components/teditorjs/lyTEditor'));

    const props = defineProps({
        field: Object,
        parentWidget: Object,
        parentList: Array,
        indexOfParentList: Number,
        designState: {
            type: Boolean,
            default: false
        },
    })
    const builderStore = useBuilderStore()
    const fieldModel = ref(null)
    let rules = ref([])
    const inputType = computed(() => {
        if (props.field.options.type === 'number') {
          return 'text'  //当input的type设置为number时，如果输入非数字字符，则v-model拿到的值为空字符串，无法实现输入校验！故屏蔽之！！
        }
        return props.field.options.type
    })
    const widgetSize = computed(() => {
        return props.field.options.size || 'default'
    })

    let {createFieldRules,getPropName,handleFieldValidation} = useCommon()
    const globalFormModel = inject("globalFormModel",null)
    function updateFormModel(value) {
        if (!!props.designState) {
            return
        }
        let keyName = getPropName(props)
        globalFormModel.value[keyName] = value
    }
    function handleBlurEvent(value) {
        updateFormModel(value)
        handleFieldValidation(getPropName(props))
    }
    
    function handleChangeEvent(value) {
        if (!!props.field.options.onChange) {
            let customFn = new Function('value', props.field.options.onChange)
            customFn(value)
        }
    }

    function initFieldModel() {
        if (!props.field.formItemFlag) {
            return
        }
        if(!(!!globalFormModel)){
            fieldModel.value = props.field.options.defaultValue
        }else if(globalFormModel.value[props.field.options.name]=== undefined && props.field.options.defaultValue !== undefined){
            fieldModel.value = props.field.options.defaultValue
        }else if(globalFormModel.value[props.field.options.name] === undefined){
            globalFormModel.value[props.field.options.name] = null
        }else {
            fieldModel.value = globalFormModel.value[props.field.options.name]
        }
    }
    watch(()=>props.field.options.defaultValue,(n,o)=>{
        fieldModel.value = n
    })
    onMounted(()=>{
        initFieldModel()
        createFieldRules(props.field,rules.value)
    })

</script>

<style lang="scss" scoped>
</style>