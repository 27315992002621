<template>
    <form-item-wrapper-ly :field="field" :rules="rules" :design-state="designState"
             :parent-widget="parentWidget" :parent-list="parentList" :index-of-parent-list="indexOfParentList">
        <el-button ref="lyfieldEditor" :type="field.options.type" :size="widgetSize"
               :plain="field.options.plain" :round="field.options.round"
               :circle="field.options.circle" :icon="field.options.icon"
               :disabled="field.options.disabled"
               :link="field.options.link"
               :loading="field.options.loading"
               :color="field.options.color"
               @click="handleButtonWidgetClick">
            {{field.options.buttonText}}</el-button>
    </form-item-wrapper-ly>
</template>

<script setup>
    import {ref, onMounted ,reactive,computed,watch} from 'vue'
    import { useBuilderStore } from "@/store/lyFormBuilder";
    import useCommon from "@/components/lyform-builder/lyform-design/useCommon";

    const props = defineProps({
        field: Object,
        parentWidget: Object,
        parentList: Array,
        indexOfParentList: Number,
        designState: {
            type: Boolean,
            default: false
        },
        rules: Array,
    })
    const builderStore = useBuilderStore()
    const fieldModel = ref(null)
    let rules = ref([])
    const widgetSize = computed(() => {
        return props.field.options.size || ''
    })

    function handleButtonWidgetClick() {
        if (!!props.designState) { //设计状态不触发点击事件
            return
        }

        if (!!props.field.options.onClick) {
            let changeFn = new Function(props.field.options.onClick)
            changeFn.call()
        }
    }

    function initFieldModel() {
        if (!props.field.formItemFlag) {
            return
        }
        fieldModel.value = props.field.options.defaultValue
    }
    let {createFieldRules} = useCommon()
    watch(()=>props.field.options.defaultValue,(n,o)=>{
        fieldModel.value = n
    })
    onMounted(()=>{
        initFieldModel()
        createFieldRules(props.field,rules.value)
    })



</script>

<style lang="scss" scoped>
    .radio-group-block {
        display: block !important;
    }
</style>