<template>
    <div>
        <el-form size="small" label-position="left" label-width="100px" class="lywidgetsetting" @submit.prevent>
            <el-collapse v-model="lyCollapseActiveList" class="lyformsetting-collapse">
                <el-collapse-item name="lyws01" title="组件属性">
                    <el-form-item label="字段标识">
                        <el-input  v-model="optionModel.name" @change="handleChange"></el-input>
                    </el-form-item>
                    <el-form-item label="隐藏">
                        <el-switch v-model="optionModel.hidden" @change="handleChange"></el-switch>
                    </el-form-item>
                    <el-form-item label="禁用">
                        <el-switch v-model="optionModel.disabled" @change="handleChange"></el-switch>
                    </el-form-item>
                    <el-form-item label="显示样式">
                        <el-radio-group v-model="optionModel.displayStyle" @change="handleChange">
                            <el-radio label="inline">行内</el-radio>
                            <el-radio label="block">块级</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="显示为按钮">
                        <el-switch v-model="optionModel.buttonStyle" @change="handleChange"></el-switch>
                    </el-form-item>
                    <el-form-item label="显示边框">
                        <el-switch v-model="optionModel.border" @change="handleChange"></el-switch>
                    </el-form-item>
                    <el-form-item label="标签文本">
                        <el-input v-model="optionModel.label"  style="width: 100%" @change="handleChange"></el-input>
                    </el-form-item>
                    <el-form-item label="标签宽度">
                        <el-input v-model="optionModel.labelWidth"  style="width: 100%" @change="handleChange"></el-input>
                    </el-form-item>
                    <el-form-item label="隐藏字段标签">
                        <el-switch v-model="optionModel.labelHidden" @change="handleChange"></el-switch>
                    </el-form-item>
                    <el-form-item label="默认值">
                        <el-input v-model="optionModel.defaultValue"  style="width: 100%" @change="handleChange"></el-input>
                    </el-form-item>
                    <el-form-item label="组件大小">
                        <el-select v-model="optionModel.size" @change="handleChange">
                            <el-option v-for="item in formSizes" :key="item.value" :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="必填字段">
                        <el-switch v-model="optionModel.required" @change="handleChange"></el-switch>
                    </el-form-item>
                    <el-form-item label="必填校验提示">
                        <el-input v-model="optionModel.requiredHint"  style="width: 100%" @change="handleChange"></el-input>
                    </el-form-item>
                    <validationCommonLy :selected-widget="selectedWidget" :option-model="optionModel"></validationCommonLy>
                    <el-form-item label="校验失败提示">
                        <el-input v-model="optionModel.validationHint"  style="width: 100%" @change="handleChange"></el-input>
                    </el-form-item>
                    <customClassLy :selected-widget="selectedWidget" :option-model="optionModel"></customClassLy>
                    <el-form-item label-width="0">
                        <el-divider>选项设置</el-divider>
                    </el-form-item>
                    <el-form-item label-width="0" class="panes-setting">
                        <el-checkbox-group  v-model="optionModel.defaultValue" @change="handleChange">
                            <draggable tag="ul" :list="optionModel.optionItems" item-key="id" v-bind="{group:'optionsGroup', ghostClass: 'ghost', handle: '.drag-option'}">
                                <template #item="{ element: tpItem, index: tpIdx }">
                                    <li class="col-item">
                                        <el-icon class="drag-option" size="18"><Rank /></el-icon>
                                        <el-checkbox :label="tpItem.value">
                                            <el-input v-model="tpItem.value" size="small" style="width: 90px"></el-input>
                                            <el-input type="text" v-model="tpItem.label" style="width: 100px"></el-input>
                                            <el-button circle plain size="small" type="danger" @click="deleteOption(tpItem, tpIdx)" icon="SemiSelect" class="col-delete-button"></el-button>
                                        </el-checkbox>
                                    </li>
                                </template>
                            </draggable>
                        </el-checkbox-group>
                        <div>
                            <el-button link type="primary" @click="addOption()" icon="plus">增加选项</el-button>
                        </div>
                    </el-form-item>
                </el-collapse-item>
                <el-collapse-item name="lyws02" title="事件属性" v-if="showEventCollapse">
                    <el-form-item label="onChange" label-width="150px">
                        <el-button type="info" icon="edit" plain round @click="editFormEventHandler('onChange',['value'])">编写代码</el-button>
                    </el-form-item>
                </el-collapse-item>
                <el-collapse-item name="lyws03" title="CRUD属性">
                    <template #title>
                        <div style="display: flex;align-items: center;">
                            CRUD属性
                            <el-tooltip effect="dark" content="后端自动生成CRUD代码，用于控制是否列表显示、可查询、可空、可编辑等属性" placement="top-start">
                                <el-icon><QuestionFilled /></el-icon>
                            </el-tooltip>
                        </div>
                    </template>
                    <crudCommonLy :selected-widget="selectedWidget" :option-model="optionModel"></crudCommonLy>
                </el-collapse-item>
            </el-collapse>
            <lyDialog v-model="showFormEventDialog" title="组件事件" width="60%" top="20px" :before-close="handleFormEventHandlerClose">
                <div>
                    <el-alert :title="currentEventNameTitle" type="info" :closable="false" />
                    <lyCodeEditor ref="lyFormEditCss" v-model="formEventCode" mode="javascript" height="600" :read-only="false"></lyCodeEditor>
                    <el-alert title="}"  :closable="false" type="info"></el-alert>
                </div>
                <template v-slot:footer>
                    <el-button @click="handleFormEventHandlerClose" size="default">关闭</el-button>
                    <el-button type="primary" @click="saveFormEvent" size="default">确定</el-button>
                </template>
            </lyDialog>
        </el-form>
    </div>
</template>

<script setup>
    import {ref, onMounted, reactive, computed, watch,defineAsyncComponent} from 'vue'
    import { useBuilderStore } from "@/store/lyFormBuilder";
    import validationCommonLy from "@/components/lyform-builder/lysetting-pannel/lywidget-common/validation-common-ly"
    import customClassLy from "@/components/lyform-builder/lysetting-pannel/lywidget-common/custom-class-ly"
    import crudCommonLy from "@/components/lyform-builder/lysetting-pannel/lywidget-common/crud-common-ly"
    import lyDialog from '@/components/dialog/dialog'
    const lyCodeEditor = defineAsyncComponent(() => import('@/components/lyform-builder/code-editor'));

    const props = defineProps({
        selectedWidget: Object,
        optionModel: Object,
    })
    const builderStore = useBuilderStore()
    function handleChange(newValue) {
         builderStore.saveCurrentHistoryStep()
    }
    let lyCollapseActiveList =  ref(['lyws01','lyws02','lyws03'])
    const formSizes = ref([
        {label: 'default', value: ''},
        {label: 'large', value: 'large'},
        {label: 'small', value: 'small'},
    ])
    function showEventCollapse(){
        if (builderStore.builderConfig['showEventCollapse'] === undefined) {
          return true
        }
        return !!builderStore.builderConfig['showEventCollapse']
    }

    function deleteOption(option, index) {
        props.optionModel.optionItems.splice(index, 1)
        builderStore.emitHistoryChange()
    }

    function addOption() {
        let newValue = props.optionModel.optionItems.length + 1
        props.optionModel.optionItems.push({
          value: newValue,
          label: 'new option'
        })
        builderStore.emitHistoryChange()
    }

    let currentEventName = ref("")//编辑选中当前事件名称
    let currentEventNameTitle = ref("")//编辑框提示事件函数全名
    let showFormEventDialog = ref(false)
    let formEventCode = ref("")
    function editFormEventHandler(flag,eventParamsName){//flag事件名、eventParamsName事件回调参数名使用数组[]
        currentEventName.value = flag
        currentEventNameTitle.value = `${props.optionModel.name}.${flag}(${eventParamsName.join(', ')}) {`
        formEventCode.value = props.selectedWidget.options[flag] || ''
        showFormEventDialog.value = true
    }
    function handleFormEventHandlerClose(){
        showFormEventDialog.value = false
    }
    function saveFormEvent(){
        builderStore.selectedWidget.options[currentEventName.value] = formEventCode.value
        builderStore.saveCurrentHistoryStep()
        showFormEventDialog.value = false
    }

</script>

<style lang="scss" scoped>
    .lywidgetsetting{
        ::v-deep(.el-form-item__label) {
          font-size: 13px;
          overflow: hidden;
          white-space: nowrap;
        }
        .lyformsetting-collapse{
            ::v-deep(.el-collapse-item__header) {
                font-weight: bold;
            }
        }
        .col-item {
            list-style: none;
            display: flex;
            align-items: center;
            margin-bottom: 5px;

            .col-delete-button {
                margin-left: 6px;
            }
        }

        .panes-setting {
            ul {
                padding-inline-start: 0;
                padding-left: 0; /* 重置IE11默认样式 */
                margin: 0;
            }

            .drag-option {
                margin-right: 5px;
                cursor: move;
            }

            li.ghost {
                background: #fff;
                border: 2px dotted var(--el-color-primary);
            }
        }
    }

</style>